svg.collaterate {
    float: right;
    margin: 5px 0 0 20px;
}

header {
    padding: 10px 0;
    color: #ffffff;
    background: #505050;
}

header a {
    transition: color 0.25s ease-in;
    color: #ffffff;
}

header a:hover {
    color: #98f9ff;
}

#supplier-menu-toggler {
    float: left;
    margin: 8px 30px 0 0;
    cursor: pointer;
}

#supplier-primary-menu {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
}

#supplier-primary-menu li {
    float: left;
    margin: 0 30px 0 0;
    padding: 0;
}

#supplier-primary-menu li a {
    padding: 11px 0 7px;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 14px;
}

#supplier-primary-menu li a#quick-search {
    cursor: pointer;
    position: relative;
}

.support-options-group {
    padding: 10px 0 20px;
    color: #777777;
}

#quick-search-inputs,
#support-options {
    display: none;
    position: fixed;
    width: 340px;
    top: 100px;
    left: 50%;
    margin-left: -170px;
    color: #333333;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 0 50px rgba(50, 50, 50, 0.6);
    background: #ffffff;
    z-index: 34;
}

#quick-search-close,
#support-options-close {
    position: absolute;
    left: 305px;
    top: 10px;
    width: 24px;
    height: 24px;
}

#quick-search-close .svg,
#support-options-close .svg {
    width: 24px;
    height: 24px;
    fill: #cccccc;
}

.quick-search-group {
    padding: 10px 0 20px;
}

#quick-search-inputs .unified-input {
    font-size: 24px;
    height: 56px;
}

#quick-search-inputs .unified-button {
    font-size: 24px;
    height: 56px;
    color: #ffffff;
    background: #747474;
}

.quick-search-tip {
    color: #777777;
    padding: 20px 0 0;
    margin: 0;
}

.count-block {
    font-family: "Proxima N W01 Light", Helvetica, Arial, sans-serif;
    font-size: 16px;
    padding: 15px;
    margin: 10px 0;
    background-color: #eeeeee;
    border: 4px solid #eeeeee;
    color: #666666;
    cursor: pointer;
    border-radius: 4px;
}

.count-block.active {
    background-color: #666666;
    border-color: #000000;
    color: #98f9ff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    border: 4px solid #ffffff;
}

.count-block div {
    font-size: 50px;
    font-family: "Proxima N W01 Bold", Helvetica, Arial, sans-serif;
}

.count-block div i {
    font-size: 25px;
    font-style: normal;
}

.count-block span {
    float: right;
    position: relative;
    top: -36px;
    right: -5px;
    color: #aaaaaa;
}

.count-block.active span {
    color: #eeeeee;
}

#supplier-primary-menu li.active a {
    color: #98f9ff;
}

.svg {
    width: 32px;
    height: 32px;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    fill: #888888;
    transition: fill ease-in-out 0.2s;
}

.svg.menu {
    fill: #ffffff;
    width: 28px;
    height: 28px;
}

.svg.quick-search {
    fill: #ffffff;
    width: 24px;
    height: 24px;
}

.svg.calculator {
    fill: #ffffff;
    width: 24px;
    height: 24px;
}

.svg.home {
    fill: #ffffff;
    width: 24px;
    height: 24px;
}

.svg.menu:hover,
.on .svg.menu,
.svg.quick-search:hover,
.svg.home:hover,
.svg.calculator:hover,
li.active svg.svg {
    fill: #98f9ff;
}

#site-navigator {
    position: relative;
    float: left;
    margin: 3px 5px 0 0;
}

#site-navigator input {
    font-family: "Proxima Nova Soft W03 Reg", Helvetica, Arial, sans-serif;
    background: #747474;
    height: 36px;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    padding: 10px 15px;
    color: #eeeeee;
    width: 300px;
    text-transform: uppercase;
    margin: 0 1px 0 0;
    float: left;
}

.site-scope #site-navigator input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

#site-navigator input:focus {
    border-color: transparent;
    outline: 0;
}

#site-navigator input::-webkit-input-placeholder { color: #eeeeee; }
#site-navigator input:-moz-placeholder { color: #eeeeee; }
#site-navigator input::-moz-placeholder { color: #eeeeee; }
#site-navigator input:-ms-input-placeholder { color: #eeeeee; }
#site-navigator input:placeholder-shown { color: #eeeeee; }

#site-navigator input.site-context::-webkit-input-placeholder { color: #98f9ff; }
#site-navigator input.site-context:-moz-placeholder { color: #98f9ff; }
#site-navigator input.site-context::-moz-placeholder { color: #98f9ff; opacity: 1; }
#site-navigator input.site-context:-ms-input-placeholder { color: #98f9ff; }
#site-navigator input.site-context:placeholder-shown { color: #98f9ff; opacity: 1; }

#recent-sites,
#all-sites {
    display: none;
    position: absolute;
    z-index: 10;
    list-style: none;
    margin: 3px 0 0;
    padding: 0;
    width: 300px;
    background: #747474;
}

#all-sites {
    z-index: 11;
}

#site-list li {
    padding: 0;
    margin: 0;
}

#site-list li.on {
    display: none;
}

#site-list li.site-list-header,
#site-list li.site-list-empty {
    padding: 9px 15px;
    color: #bbbbbb;
    border-bottom: 1px solid #656565;
}

#site-list li.site-list-empty {
    color: #ffffff;
    font-style: italic;
}

#page-container {
    position: relative;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    flex-grow: 1;
    display: flex;
}

#page-container > * {
    flex-grow: 0;
    align-content: stretch;
}

#page-container:after {
    content: "";
    display: table;
    clear: both;
}

#supplier-menu {
    position: absolute;
    height: 100%;
    width: 432px;
    margin-left: -432px;
    padding: 5px 20px;
    color: #ffffff;
    background: #505050 url(/web3/build/admin/images/supplier-menu-bg.jpg) repeat-y 0 0;
    border-right: 12px solid #ffffff;
    box-shadow: inset 0 0 10px rgba(1, 1, 1, 0.8);
    transition: margin 0.25s ease-in;
}

#supplier-menu ul {
    margin: 0 0 10px;
    list-style: none;
}

#supplier-menu ul li {
    margin: 0;
    text-transform: uppercase;
    color: #b1b1b1;
    font-size: 14px;
}

#supplier-menu ul li a {
    display: block;
    padding: 2px 0;
    text-decoration: none;
    text-transform: none;
    color: #ffffff;
}

#supplier-menu ul li.active > a,
#supplier-menu ul li > ul li.active > a {
    color: #dddddd;
}

#supplier-menu ul li > ul {
    margin-bottom: 0;
}

#supplier-menu ul li > ul li {
    padding-left: 10px;
    font-size: 12px;
}

#supplier-menu h6 {
    font-family: "Proxima N W01 Thin Reg", Helvetica, Arial;
    font-size: 18px;
    padding: 20px 0 0;
    margin: 0 0 10px;
    text-transform: uppercase;
}

.menu-active #supplier-menu {
    margin-left: 0;
}

.menu-active #content {
    margin-left: 432px;
}

#work-queues { color: #ffc047; }
#job-costing { color: #e9ff2f; }
#statistics { color: #1afff7; }
#sales { color: #fc6621; }
#settings { color: #ff92e9; }

.close-supplier-menu {
    position: absolute;
    right: 14px;
    top: 8px;
    height: 22px;
    width: 22px;
    cursor: pointer;
}

#content {
    margin-left: 0;
    width: 100%;
    border-top: 12px solid #ffffff;
    transition: margin 0.25s ease-in;
    display: flex;
    flex-shrink: 0;
}

#content:after {
    content: "";
    display: table;
    clear: both;
}

#site-nav {
    width: 200px;
    padding: 20px 0 10px 15px;
    background: #9d9d9d;
    flex-shrink: 0;
    flex-grow: 0;
}

#main {
    margin: 20px 0 0 20px;
    flex-grow: 1;
}

.site-scope #main {
    margin-left: 10px;
}

#site-nav ul {
    list-style: none;
    margin: 0 0 20px;
}

#site-nav ul li {
    margin: 0 0 4px;
    padding: 2px 0;
    color: #f2ff9e;
    text-transform: uppercase;
    font-size: 15px;
    font-family: "Proxima N W01 Thin Reg", Helvetica, Arial, sans-serif;
}

#site-nav ul li.active {
    background: #afafaf;
    border-right: 4px solid #f2ff9e;
}

#site-nav ul li a {
    display: block;
    text-decoration: none;
    margin-left: 30px;
    color: #ffffff;
    text-transform: none;
    font-size: 14px;
    font-family: "Proxima Nova Soft W03 Reg", Helvetica, Arial, sans-serif;
}

#site-nav ul li svg {
    width: 16px;
    height: 16px;
    position: relative;
    top: -1px;
    margin: 0 7px;
    fill: #f2ff9e;
}

#site-nav #site-primary-links {
    margin-bottom: 10px;
}

#site-nav #site-primary-links li a {
    margin-left: 4px;
    font-family: "Proxima Nova Soft W03 Smbd", Helvetica, Arial, sans-serif;
}

#site-nav ul #site-home a {
    font-size: 17px;
    color: #f2ff9e;
}

#site-list {
    clear: both;
}

#fromDate input {
    width: 100px;
}

#promotion .promotionSelect {
    float: left;
    width: 75%;
}

#promotion .promotionCode {
    float: left;
    width: 25%;
    padding-left: 10px;
}

#promotion .form-control {
    font-size: 12px;
    height: 32px;
    padding: 0;
}

#promotion .select2-choice {
    padding: 4px 8px;
    height: 30px;
}

.unified-input-and-button {
    position: relative;
    display: table;
    border-collapse: separate;
}

.unified-input {
    display: table-cell;
    z-index: 2;
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 0;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.4;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #cccccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.unified-button-wrap {
    display: table-cell;
    position: relative;
    font-size: 0;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
}

.unified-button {
    box-sizing: border-box;
    margin-left: -1px;
    display: inline-block;
    position: relative;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 34px;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid #cccccc;
}

.back-to-list a {
    display: inline-block;
}

.back-to-list .svg {
    width: 30px;
    height: 30px;
    fill: #23abc3;
}

.storefront-link {
    display: inline-block;
    background: #747474;
    height: 36px;
    padding: 6px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.storefront-link.for-site-admin {
    vertical-align: top;
    padding: 10px 0 2px 20px;
    background: none;
}

.storefront-link:hover svg {
    fill: #98f9ff;
}

.svg.storefront {
    width: 26px;
    height: 26px;
    fill: #eeeeee;
    margin: 0 5px 0 0;
}

.svg.impersonate {
    width: 22px;
    height: 22px;
}

#admin-user {
    float: right;
    padding: 11px 0 7px;
}

#admin-user a {
    text-decoration: none;
    color: #eeeeee;
}

#admin-user a:hover {
    color: #98f9ff;
}

#admin-user a:hover svg,
#admin-user.active svg.svg {
    fill: #98f9ff;
}

.svg.user {
    fill: #eeeeee;
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

#messages {
    float: right;
    margin: 0 20px 0 15px;
    padding: 11px 0 7px;
}

.message-count {
    text-decoration: none;
    display: inline-block;
}

.svg.chat {
    fill: #ffffff;
    width: 24px;
    height: 24px;
    margin-left: 4px;
}

.message-count:hover svg {
    fill: #98f9ff;
}

.site-name {
    font-size: 28px;
    font-family: "Proxima N W01 Light", Helvetica, Arial, sans-serif;
}

#pageControlsContainer:after {
    content: "";
    display: table;
    clear: both;
}

.page-masthead h1 {
    float: left;
    width: auto;
}

.back-to-list {
    float: left;
    width: 45px;
    padding: 6px 0 0;
}

.top-save {
    float: right;
    margin: 8px 0 0 20px;
}

.inlineSelect {
    display: inline-block;
    position: relative;
    padding-right: 24px;
    border-bottom: 1px solid;
}

.inlineSelect:after {
    content: " ";
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 0;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 6px solid;
    margin-top: -3px; /* half the border-top value */
}

.inlineSelect-input {
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: none;
    height: auto;
    margin: 0;
    cursor: pointer;
    border: 0;
    border-radius: 0;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    font-size: 16px;
    opacity: 0;
    z-index: 1;
}

.inlineSelect:focus {
    outline: 0;
}

.island {
    padding: 100px 0;
}

.loadingSpinner {
    min-width: 32px;
    min-height: 32px;
    text-indent: -9999px;
    background: transparent url("/web3/build/admin/images/spinner_big.gif") no-repeat center center;
}

#notification-panel {
    position: fixed;
    width: 40%;
    right: 0;
    z-index: 38;
    top: 75px;
    bottom: 0;
    color: #5f5f5f;
    background: #ffffff;
    padding: 20px;
    min-width: 380px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

#notification-panel h2 {
    margin: 0;
}

#notification-panel a {
    color: #506986;
}

.notifications-in-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-right: 20px;
}

.notifications-container {
    overflow: hidden;
}

.notification {
    float: left;
    width: 100%;
    border-bottom: 1px solid #dddddd;
}

.notification-timestamp {
    font-size: 16px;
    margin: 0 0 8px;
    font-family: "Proxima N W01 Light", Helvetica, Arial, sans-serif;
}

#notification-panel .notification-timestamp a {
    color: #23abc3;
    text-decoration: none;
}

.notification-subject {
    font-weight: bold;
    margin: 0 0 8px;
    font-family: "Proxima Nova Soft W03 Smbd", Helvetica, Arial, sans-serif;
}

.svg.quotation {
    float: left;
    width: 16px;
    height: 16px;
    fill: #eeeeee;
    vertical-align: top;
}

.dismissed .svg.quotation {
    fill: #cccccc;
}

.notification-body {
    margin: 0 0 5px 25px;
    max-height: 2000px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.dismissed .notification-body {
    max-height: 20px;
    overflow: hidden;
}

.dismiss-actions {
    float: right;
    margin: 10px 0;
    width: 15%;
    text-align: right;
    min-width: 80px;
}

.reply-actions {
    margin: 10px 0;
    float: left;
    width: 85%;
    max-width: 260px;
}

.reply-actions.replying {
    width: 80%;
    min-width: 320px;
    max-width: none;
}

.reply-actions textarea {
    font-size: 12px;
    height: 80px;
}

.notifications-in-panel button.secondary {
    font-weight: normal;
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap;
}

.a-notification {
    float: left;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    margin: 0 0 20px;
    padding: 5px 5px 10px;
}

.a-notification_internal {
    border-left: 8px solid #23abc3;
    padding-left: 10px;
}

.hideInternal .a-notification_internal { display: none; }
.hideExternal .a-notification:not(.a-notification_internal) { display: none; }

.other-csr-pill {
    float: right;
    font-size: 12px;
    font-weight: bold;
    background-color: #b2ebf2;
    padding: 5px 15px;
    border-radius: 15px;
}

.a-notification.dismissed {
    background: #f0f0f0;
    opacity: 0.4;
}

.dismiss-actions a {
    cursor: pointer;
    margin: 5px;
    opacity: 1;
}

button.secondary .svg.reply,
button.secondary .svg.dismiss {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    position: relative;
    top: -1px;
}

button.secondary .svg.dismiss {
    width: 12px;
    height: 12px;
}

button.secondary.send-reply {
    background: #eafbd7;
}

.panel-close {
    float: right;
    margin: 0 0 10px 10px;
    cursor: pointer;
}

.svg.close {
    width: 22px;
    height: 22px;
}

.close:hover {
    fill: #cccccc;
}

.spacer-10 { margin-bottom: 10px; }
.spacer-20 { margin-bottom: 20px; }
.spacer-40 { margin-bottom: 40px; }
.spacer-60 { margin-bottom: 60px; }

select.jumbo {
    font-size: 20px;
    height: 46px;
}

.pill-filter.jumbo {
    font-size: 16px;
}

.primary-list-filters {
    margin-bottom: 60px;
    padding-right: 20px;
}

.list-controls {
    float: left;
    width: 100%;
    background-color: #eeeeee;
    padding-top: 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 2px solid #dddddd;
}

.list-controls.bottom {
    padding: 0 0 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 2px solid #dddddd;
}

.list-quick-filter .action {
    position: relative;
    top: 8px;
    padding-right: 5px;
}

.list-quick-filter .action input[type=checkbox] {
    vertical-align: baseline;
}

.list-quick-filter input,
.list-quick-filter select,
.list-quick-filter button {
    width: 140px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: text-top;
}

.list-controls-ft {
    padding: 0 3px;
}

.list-paging {
    float: left;
    width: 100%;
    padding: 20px 0;
}

.result-counts {
    font-family: "Proxima N W01 Light", Helvetica, Arial, sans-serif;
    font-size: 22px;
}

.paging-utils {
    text-align: right;
}

.paging-utils select,
.paging-utils input {
    display: inline-block;
    width: 120px;
    background-color: #eeeeee;
}

.paging-utils input {
    vertical-align: middle;
}

.page-navigator {
    display: inline-block;
    margin-right: 20px;
    text-align: right;
}

.results-per-page {
    display: inline-block;
}

button.secondary.pager {
    height: 32px;
    vertical-align: middle;
    line-height: 0;
    background: #eeeeee;
    padding: 0 8px;
    border-color: #cccccc;
}

button.secondary.pager[disabled] {
    color: #aaaaaa;
}

.mini-paging:before,
.mini-paging:after {
    display: table;
    content: " ";
}

.mini-paging:after {
    clear: both;
}

.mini-paging {
    padding: 5px 0 12px;
}

.mini-paging .result-counts {
    float: none;
    width: auto;
    font-size: 12px;
    text-align: right;
}

.mini-paging .paging-utils {
    float: none;
    width: auto;
    text-align: right;
}

.mini-paging .page-navigator {
    margin: 0;
}

.mini-paging .page-navigator select {
    height: 20px;
    font-size: 10px;
}

.mini-paging button.secondary.pager {
    height: 20px;
    padding: 0 5px;
    font-size: 12px;
}

.queue-controls {
    float: right;
    margin-top: 5px;
}

.queue-controls a {
    text-decoration: none;
    margin-left: 10px;
}

.refresh-list {
    float: right;
    margin-top: 20px;
}

svg.refresh {
    cursor: pointer;
}

svg.refresh:hover {
    fill: #cccccc;
}

h1 {
    font-size: 38px;
    line-height: 42px;
    font-weight: normal;
    font-family: "Proxima N W01 Light", Helvetica, Arial, sans-serif;
}

.c-dollar-value {
    white-space: nowrap;
}

.table-wrap {
    word-wrap: anywhere;
}

.list-table {
    width: 100%;
    margin-bottom: 20px;
}

.list-table.bordered {
    border: 1px solid #dddddd;
}

.list-table > thead > tr > th,
.list-table > tbody > tr > th,
.list-table > tfoot > tr > th,
.list-table > thead > tr > td,
.list-table > tbody > tr > td,
.list-table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #dddddd;
}

.list-table tr th {
    font-weight: normal;
    vertical-align: bottom;
    border-bottom: 2px solid #dddddd;
    background-color: #eeeeee;
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: nowrap;
    font-family: "Proxima Nova Soft W03 Smbd", Helvetica, Arial, sans-serif;
}

.list-table > caption + thead > tr:first-child > th,
.list-table > colgroup + thead > tr:first-child > th,
.list-table > thead:first-child > tr:first-child > th,
.list-table > caption + thead > tr:first-child > td,
.list-table > colgroup + thead > tr:first-child > td,
.list-table > thead:first-child > tr:first-child > td {
    border-top: 0;
}

.list-table > tbody + tbody {
    border-top: 2px solid #dddddd;
}

.list-table th.sortable {
    cursor: pointer;
}

.list-table tr:hover {
    background: #fafbfd;
    cursor: pointer;
}

.list-table tr.hasIssue {
    background: #f5fc89;
}

.list-table tr.needsApproval {
    background: #ffeded;
}

.list-table tr.newFileAlert {
    background: #edffff;
}

.list-table tr.prepressAlert {
    background: #ffeded;
}

.list-table tr.dataTeamAlert {
    background: #d3f6d5;
}

.list-table tr.highlighted {
    background: #f5feef;
}

.list-table_noAction tr:hover {
    background-color: transparent;
    cursor: default;
}

.list-table_noAction tr.highlighter:hover {
    background-color: #f3fdb3;
}

.list-table_hoverHighlight tr:hover {
    cursor: default;
}

.list-table.gang tr:hover {
    background: transparent;
    cursor: default;
}

.list-table.gang > thead > tr > th,
.list-table.gang > tbody > tr > td {
    text-align: center;
}

.list-table.gang > tbody > tr > td.count:hover {
    background: #e5e5e5;
    cursor: pointer;
}

td.noAction:hover {
    cursor: auto;
}

.list-table.vertical tr th {
    border-top: 0;
    border-width: 1px;
    background-color: transparent;
}

.list-table.vertical tr td {
    border-bottom: 1px solid #dddddd;
    border-top: 0;
}

.sortable .sorted {
    display: block;
}

.sortable .sorted span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    background: transparent url(/web3/build/admin/images/sort-asc.png) no-repeat;
    vertical-align: middle;
}

.sortable .sorted span.desc {
    background-image: url(/web3/build/admin/images/sort-desc.png);
}

.mc-search-wrapper {
    height: 35px;
    position: relative;
    background: #f6f7f9;
    padding-top: 1px;
}

input.multichooser-search {
    position: absolute;
    width: 70%;
}

ul.mc-list {
    height: 165px;
    overflow: auto;
}

ul.mc-list li {
    margin-left: 5px;
}

.button-group {
    float: left;
}

.button-group button {
    display: inline-block;
    position: relative;
    margin: 0 0 0 -1px;
    float: left;
    font-family: "Proxima Nova Soft W03 Reg", Helvetica, Arial;
    text-transform: uppercase;
    color: #333333;
    border: 1px solid #b2b3b6;
    background: #efefef;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    height: 36px;
    white-space: nowrap;
    padding-left: 20px;
    padding-right: 15px;
    transition: background 0.5s ease-in-out;
}

.button-group button:first-child,
.button-group button:first-child div {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.button-group button:last-child,
.button-group button:last-child div {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.button-group button:focus {
    z-index: 2;
    outline: none;
}

.button-group button div {
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    background: #e9ff90;
    transition: width 0.4s ease-in-out;
}

.button-group button.status-set div {
    width: 100%;
}

.button-group button span {
    position: relative;
}

.modal-buttons {
    float: right;
    margin-top: 5px;
}

.modal-buttons .btnPlain {
    margin-left: 20px;
}

.close-panel {
    width: 24px;
    height: 24px;
    margin-left: 20px;
    cursor: pointer;
}

.svg.light {
    fill: #cccccc;
}

.svg.light:hover {
    fill: #ffffff;
}

.modal-header-link .svg {
    margin-top: -9px;
    fill: #009db0;
    transition: all 0.3s ease-in-out;
}

.modal-header-link:hover .svg {
    fill: #555555;
}

.input-bundle {
    display: table;
    vertical-align: middle;
    position: relative;
    border-collapse: separate;
    width: 100%;
}

.input-bundle .input-bundle-start,
.input-bundle input {
    display: table-cell;
}

.inset {
    padding: 20px;
    box-shadow: inset 0 1px 2px 1px rgba(100, 100, 100, 0.2);
    background: #eff0f2;
    border-radius: 4px;
}

.go-to-shipment {
    display: block;
    padding: 0 0 0 20px;
    background: transparent url(/web3/build/admin/images/truck.png) no-repeat 0 0;
}

a.clear-input {
    position: relative;
    display: inline-block;
    visibility: visible;
    left: -30px;
}

svg.svg.clear-input {
    width: 16px;
    height: 16px;
    fill: #dddddd;
}

.svg.mix--svg_autoSize {
    display: block;
    width: 100%;
    height: 100%;
}

.svg.mix--svg_sm {
    width: 16px;
    height: 16px;
}

.svg.mix--svg_md {
    width: 24px;
    height: 24px;
}

.button-group button.status-set + button:not(.status-set):after,
.button-group button.status-set + button:not(.status-set):before {
    left: -1px;
    top: 50%;
    border: 0 solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    z-index: 3;
}

.button-group button.status-set + button:not(.status-set):after {
    border-color: rgba(233, 255, 144, 0);
    border-left-color: #e9ff90;
    border-width: 17px;
    margin-top: -17px;
}

.button-group button.status-set + button:not(.status-set):before {
    border-color: rgba(51, 51, 51, 0);
    border-left-color: #a7a8a9;
    border-width: 18px;
    margin-top: -18px;
}

.production-status-toggler {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.production-status-change-buttons {
    text-align: right;
    padding-bottom: 10px;
}

.production-status-change-buttons button.save,
.production-status-change-buttons button.cancel {
    margin: 0 0 0 10px;
}

/*
Some icons by Yusuke Kamiyamane (http://p.yusukekamiyamane.com/). All rights reserved.
*/
@font-face {
    font-family: "JournalRegular";
    src: url("/web3/build/admin/font/journal-webfont.eot");
    src: url("/web3/build/admin/font/journal-webfont.woff") format("woff"),
        url("/web3/build/admin/font/journal-webfont.ttf") format("truetype"),
        url("/web3/build/admin/font/journal-webfont.svg#webfont") format("svg");
    font-weight: normal;
    font-style: normal;
}

body {
    color: #5f5f5f;
    background: #f6f7f9;
}

html,
body,
body > form[name="theform"] {
    min-height: 100%;
}

body > form[name="theform"] {
    display: flex;
    flex-direction: column;
}

body > form[name="theform"] > * {
    flex-grow: 0;
}

#contentArea {
    margin-bottom: 100px;
    min-height: 350px;
}

a {
    color: #506986;
}

a:focus {
    outline: 0;
}

a.updatePassword {
    display: block;
    width: 200px;
    padding: 3px 0 3px 22px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/lockEdit.png) no-repeat 0 0;
}

.breakBefore {
    page-break-before: always;
}

em {
    color: #a10d25;
    font-style: inherit;
}

blockquote em {
    color: #5f5f5f;
}

.required {
    color: #a10d25;
}

.c-icon {
    width: 32px;
    height: 32px;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    margin: 0;
    float: none;
    fill: #888888;
    transition: fill ease-in-out 0.2s;
}

.c-tier {
    width: 16px;
    height: 16px;
    margin-left: 2px;
}

.c-tier.on {
    fill: #71ab47;
}

div.section {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 2px;
    background: transparent url("/web3/build/admin/images/rowSeparator.png") repeat-x scroll top left;
}

h2 {
    padding-bottom: 0.5em;
    font-size: 20px;
    font-size: 2rem;
    line-height: 1;
    background: transparent url("/web3/build/admin/images/rowSeparator.png") repeat-x scroll bottom left;
}

h3 {
    font-size: 16px;
    font-size: 1.6rem;
}

h3.withIcon {
    padding-left: 25px;
    margin-bottom: 10px;
    background: transparent url("") 0 3px no-repeat;
}

h3.withIcon.big {
    padding: 6px 0 4px 40px;
    background-position: 0 0;
}

h3.withIcon.prepress { background-image: url(/web3/build/admin/images/prepressIcon.png); }
h3.withIcon.production { background-image: url(/web3/build/admin/images/productionIcon.png); }
h3.withIcon.shipping { background-image: url(/web3/build/admin/images/truck32.png); }
h3.withIcon.divisions { background-image: url(/web3/build/admin/images/divisions.png); }
h3.withIcon.currency { background-image: url(/web3/build/admin/images/currency.png); }

h4 {
    margin-bottom: 10px;
    font-size: 15px;
}

h5 {
    font-size: 15px;
    margin-bottom: 10px;
}

ul.sectionBanner {
    margin: 0;
    padding: 0;
    clear: both;
    overflow: visible;
}

ul.sectionBanner li {
    list-style-type: none;
    margin: 20px 0 10px;
    padding: 5px 15px 5px 20px;
    width: 100%;
    font-size: 21px;
    font-weight: bold;
    background: #eeeeee;
    border-bottom: 2px solid #dddddd;
}

h3.section {
    float: left;
    width: 100%;
    font-size: 24px;
    padding: 20px;
    margin: 20px 0 0;
    font-style: normal;
    border-top: 1px solid #ececec;
}

.quote-defaults-editor {
    height: 500px;
    box-sizing: border-box;
    width: 100%;
    font-family: monospace;
    font-size: 12px;
    line-height: 1.5;
    box-shadow: inset 0 1px 5px 1px rgba(200, 200, 200, 0.3);
    padding: 10px;
    border-color: #cccccc;
}

a.calc-override {
    float: right;
    text-decoration: none;
    padding-right: 20px;
    background: #f3fdb3 url(/web3/build/admin/images/calc-default-override.png) no-repeat 100% 0;
}

a.calc-override.no-overrides {
    background-image: url(/web3/build/admin/images/calc-default-override-off.png);
    background-color: transparent;
}

strong.alert {
    color: #a10d25;
}

a.add {
    color: #71ab47;
    text-decoration: none;
}

a.remove,
a.cancel {
    color: #a10d25;
    text-decoration: none;
    padding: 5px 5px 5px 20px;
    background: transparent url(/web3/build/admin/images/delete.png) 0% 50% no-repeat;
}

a.cancel {
    color: inherit;
    background-image: url(/web3/build/admin/images/cancel.png);
}

a.edit {
    text-decoration: none;
    padding: 5px 5px 5px 20px;
    background: transparent url(/web3/build/admin/images/pencil.png) 0% 50% no-repeat;
}

a.edit.dim {
    background-image: url(/web3/build/admin/images/pencil-gray.png);
}

a.edit.dim:hover {
    background-image: url(/web3/build/admin/images/pencil.png);
}

a.uploadImage,
a.replaceImage {
    color: #71ab47;
    text-decoration: none;
    padding: 5px 5px 5px 20px;
    background: transparent url(/web3/build/admin/images/imageAdd.png) 0% 50% no-repeat;
}

a.replaceImage {
    color: #9fa818;
    background-image: url(/web3/build/admin/images/imageReplace.png);
}

.pendingDelete {
    text-decoration: line-through;
}

a.image {
    padding: 5px 5px 5px 20px;
    background: transparent url(/web3/build/admin/images/imageIcon.png) 0% 50% no-repeat;
}

a.viewHistory {
    padding: 5px 5px 5px 20px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/ordersIcon.png) 0% 50% no-repeat;
}

.auditTermsOrders {
    padding: 5px 5px 5px 55px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/irs.png) 0% 50% no-repeat;
}

.divisionTaxExempt {
    padding: 5px 5px 5px 31px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/exempt.png) 0% 50% no-repeat;
}

a.editStatus {
    padding: 5px 0 5px 20px;
    background: transparent url(/web3/build/admin/images/editStatus.png) 0% 50% no-repeat;
}

a.editInPanel {
    float: right;
    padding: 16px 0 0 20px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/pencil-gray.png) no-repeat scroll 0 50%;
}

a.editInline {
    padding: 0 10px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/pencil-gray.png) no-repeat scroll 0 50%;
    display: inline-block;
}

a.editInline:hover {
    background-image: url(/web3/build/admin/images/pencil.png);
    cursor: pointer;
}

div.highlightContainer:hover a.editInPanel {
    background-image: url(/web3/build/admin/images/pencil.png);
}

a.editInPanel.on {}

a.verifyMailAddressIcon {
    float: right;
    padding: 16px 0 0 20px;
    margin-top: 12px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/mail_envelope_check3.png) no-repeat scroll 0 0;
}

a.allocate {
    float: right;
    padding: 5px 0 5px 20px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/split.png) no-repeat scroll 0 50%;
}

a.auto-allocate {
    float: right;
    padding: 5px 0 5px 20px;
    margin-right: 10px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/arrowExpand.png) no-repeat scroll 0 50%;
}

#adminHeader.login {
    height: 110px;
}

/**** ADMIN HEADER ****/
#header {
    position: relative;
    height: 60px;
    white-space: nowrap;
}

ul.topNav {
    float: left;
    height: 35px;
    padding-top: 25px;
    list-style: none;
}

ul.topNav li {
    float: left;
    padding: 0 5px 0 0;
    margin: 0;
}

ul.topNav li a {
    float: left;
    height: 20px;
    padding: 8px 10px 7px;
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
    text-shadow: rgba(10, 10, 10, 0.8) 0 1px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #557aa0;
}

ul.topNav li a.system {
    padding-left: 27px;
    background: #557aa0 url(/web3/build/admin/images/systemIcon.png) no-repeat scroll 6px 10px;
}

ul.topNav li a.site {
    border-top-right-radius: 0;
}

ul.topNav li.on a {
    color: inherit;
    text-shadow: none;
    background-color: #ffffff;
}

ul.topNav li a.closeTab {
    width: 15px;
    padding-left: 0;
    border-top-left-radius: 0;
    background: #557aa0 url(/web3/build/admin/images/closeTab.png) no-repeat center center;
}

ul.topNav li.on a.closeTab {
    background-color: #ffffff;
}

ul.topNav li a.addSiteTab {
    width: 16px;
    background: #557aa0 url(/web3/build/admin/images/add.png) no-repeat center center;
}

/***** HEADER SUBNAV AND DROPDOWNS *****/
.subNavContainer {
    float: left;
    width: 100%;
    padding: 5px 0 0;
    background: #ffffff;
}

.siteLinkHolder {
    height: 25px;
}

a.storefrontLink {
    float: right;
    margin-right: 10px;
    text-decoration: none;
}

h1.siteName,
h1.systemName {
    float: left;
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-style: normal;
    font-variant: normal;
    font-size: 27px;
    font-size: 2.7rem;
    line-height: 1.3;
    font-family: Arial, Helvetica, "Bitstream Vera Sans", sans-serif;
    letter-spacing: -0.03em;
}

h1.systemName {
    padding-left: 40px;
    background: transparent url(/web3/build/admin/images/systemIcon32.png) 0% 50% no-repeat;
}

ul.subnav {
    list-style: none;
    margin: 0;
    float: right;
}

ul.subnav li {
    float: left;
    margin: 0;
    padding: 0;
    position: relative; /* --Declare X and Y axis base-- */
}

ul.subnav li a {
    float: left;
    padding: 10px 12px 10px 10px;
    font-size: 12px;
    text-decoration: none;
}

ul.subnav li.active a {
    color: #212121;
}

ul.subnav li a.menuHeader {
    padding-right: 21px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: transparent url(/web3/build/admin/images/ddArrowOff.png) no-repeat 100% 52%;
}

ul.subnav li a.menuHeader.on {
    color: #ffffff;
    background-color: #506986;
    background-image: url(/web3/build/admin/images/ddArrowOn.png);
}

ul.subnav li ul.ddnav {
    display: none;
    list-style: none;
    position: absolute;

    /* --Important - Keeps ddnav from affecting main navigation flow-- */
    left: 0;
    top: 38px;
    margin: 0;
    padding: 5px 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #506986;
    z-index: 1;
}

ul.subnav li ul.ddnav li {
    margin: 0;
    padding: 0;
    clear: both;
}

html ul.subnav li ul.ddnav li a {
    display: block;
    width: 165px;
    padding: 7px 5px 7px 20px;
    color: #f6f7f9;
    border-top: 1px solid #465c76;
    border-bottom: 1px solid #607792;
    background: transparent url(/web3/build/admin/images/ddNavBullet.png) no-repeat 5px 12px;
}

html ul.subnav li ul.ddnav li a.primary {
    font-weight: bold;
}

html ul.subnav li ul.ddnav li a.tertiary {
    width: 150px;
    padding-left: 35px;
    background-position: 20px 12px;
}

ul.subnav li ul.ddnav li.active a {
    background-color: #607792;
}

html ul.subnav li ul.ddnav li a:hover {
    color: #ffffff;
    background-color: #607792;
}

a.sectionSubNav {
    float: left;
    padding: 8px 12px 8px 30px;
    color: #8b8b8b;
    text-decoration: none;
    font-size: 14px;
    border-radius: 8px;
    background: #ffffff url("") 5px 8px no-repeat;
}

a.sectionSubNav:hover {
    color: inherit;
}

a.sectionSubNav.on {
    box-shadow: 0 1px 3px rgba(90, 90, 90, 0.5);
}

a.sectionSubNav.divisions {
    background-image: url(/web3/build/admin/images/divisions.png);
}

a.sectionSubNav.users {
    background-image: url(/web3/build/admin/images/users.png);
}

.productListChooser {
    margin-bottom: 20px;
}

a.sectionSubNav.productListType {
    margin-right: 10px;
    background-image: url(/web3/build/admin/images/radios.png);
    background-position: 8px 10px;
}

a.sectionSubNav.productListType.on {
    color: #71ab47;
    background-position: 8px -20px;
}

/**** PAGE CONTROLS ****/
.pageControlsContainer {
    float: left;
    width: 100%;
}

.pageControlsContainer.crumbs {
    margin-bottom: 10px;
}

#pageControls {
    float: left;
    width: 100%;
}

#pageControls.floatControls {
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 20px 0;
    z-index: 24;
    box-shadow: 0 2px 2px rgba(90, 90, 90, 0.5);
    background: #ffffff;
}

.site-scope #pageControls.floatControls {
    padding-left: 210px;
}

#pageControls h1 {
    padding-top: 0;
}

.persistent-search {
    position: absolute;
    left: 4px;
    top: 4px;
}

.persistent-search .quick-search {
    fill: #333333;
    width: 16px;
    height: 16px;
}

.quickSearchContainer {
    float: left;
    width: 100%;
}

a.quickSearch {
    float: left;
    position: relative;
    left: 10px;
    width: 20px;
    height: 20px;
    background: transparent url(/web3/build/admin/images/find_off.png) no-repeat 0 0;
}

a.quickSearch:hover {
    background-image: url(/web3/build/admin/images/find.png);
}

.quickSearchPanel {
    position: absolute;
    width: 330px;
    margin: 20px 0 0 10px;
    padding: 5px;
    border-bottom: 1px solid #1d3752;
    border-radius: 8px;
    border-top-left-radius: 0;
    color: #ffffff;
    font-weight: bold;
    background: #506986;
    box-shadow: 0 4px 3px rgba(90, 90, 90, 0.7);
    z-index: 25;
}

.floatControls .quickSearchPanel {
    margin-top: 63px;
}

.crumbs .floatControls .quickSearchPanel {
    margin-top: 69px;
}

a.closeQuickSearch {
    float: right;
    width: 20px;
    height: 20px;
    border: 0;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/closePanel.png) no-repeat top left;
}

.qsearch {
    float: left;
    margin: 15px;
}

.qsearch label {
    display: block;
}

.qsearch input {
    width: 60px;
}

.qsearchMessages div#userMessageError {
    padding-bottom: 0;
    background-image: url(/web3/build/admin/images/exclamation.png);
    background-position: 15px 7px;
}

.qsearchTips {
    float: right;
    font-size: 10px;
}

/**** NAVIGATION CRUMBS ****/
.adminProductLinks {
    float: right;
}

.adminProductLinks .backToParent {
    display: block;
    padding: 3px 5px 3px 20px;
    margin-bottom: 3px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/arrowUpLeft.png) 0 50% no-repeat;
}

ul.titleCrumbs {
    margin-bottom: 10px;
    list-style: none;
    overflow: hidden;
    font-size: 18px;
    font-weight: bold;
}

ul.titleCrumbs li {
    margin: 0;
    float: left;
}

ul.titleCrumbs li a {
    color: #ffffff;
    text-decoration: none;
    padding: 6px 0 6px 50px;
    background: #506986;
    position: relative;
    display: block;
    float: left;
}

ul.titleCrumbs li a:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;

    /* Go big on the size, and let overflow hide */
    border-bottom: 50px solid transparent;
    border-left: 30px solid #506986;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
}

ul.titleCrumbs li a:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
}

ul.titleCrumbs li:first-child a {
    padding-left: 10px;
}

ul.titleCrumbs li:nth-child(2) a {
    background: #6d829a;
}

ul.titleCrumbs li:nth-child(2) a:after {
    border-left-color: #6d829a;
}

ul.titleCrumbs li:nth-child(3) a {
    background: #8899ac;
}

ul.titleCrumbs li:nth-child(3) a:after {
    border-left-color: #8899ac;
}

ul.titleCrumbs li:nth-child(4) a {
    background: hsla(34, 85%, 65%, 1);
}

ul.titleCrumbs li:nth-child(4) a:after {
    border-left-color: hsla(34, 85%, 65%, 1);
}

ul.titleCrumbs li:nth-child(5) a {
    background: hsla(34, 85%, 75%, 1);
}

ul.titleCrumbs li:nth-child(5) a:after {
    border-left-color: hsla(34, 85%, 75%, 1);
}

ul.titleCrumbs li:last-child a {
    background: transparent !important;
    padding: 8px 0 0 40px;
    color: #5f5f5f;
    line-height: 1em;
    font-size: 23px;
    line-height: 23px;
    font-weight: bold;
    pointer-events: none;
    cursor: default;
    width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

ul.titleCrumbs li:last-child a:after,
ul.titleCrumbs li:last-child a:before {
    border: none;
}

/**** DASHBOARD ****/
table.resultsList.siteLauncher td {
    font-size: 1.1em;
}

td.siteName a {
    display: block;
    text-decoration: none;
    font-weight: bold;
    padding-top: 3px;
}

a.dashboardReport {
    display: block;
    padding: 1px 0 3px 25px;
    margin: 0 0 9px 15px;
    text-decoration: none;
    background: transparent 0 0 no-repeat;
}

a.dashboardReport.pressSheets { background-image: url(/web3/build/admin/images/pressSheets.png); }
a.dashboardReport.runtime { background-image: url(/web3/build/admin/images/runtime.png); }

div.spinnerBig {
    background: transparent url(/web3/build/admin/images/spinner_big.gif) center center no-repeat;
}

/* old dashboard styles below? */
ul.dashboardSubMenu {
    float: right;
    text-align: right;
}

ul.dashboardSubMenu li {
    display: inline;
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.dashboardSubMenu li a {
    float: left;
    padding: 8px 10px;
    text-decoration: none;
}

ul.dashboardSubMenu li.on a {
    color: #333333;
    border-radius: 8px;
    box-shadow: inset 0 1px 2px rgba(90, 90, 90, 0.5);
    background: #eef0f3;
}

.dashboardGroup {
    height: 170px;
    padding: 10px 0 30px;
    margin-bottom: 20px;
    border-radius: 8px;
    border-bottom: 1px solid #dfe1e4;
    background: #eef0f3 none no-repeat 50% 100%;
    cursor: pointer;
}

.dashboardGroup.active {
    background-color: #ffffff;
    background-image: url(/web3/build/admin/images/downArrowBig.png);
    cursor: default;
    border-bottom: 1px solid #ffffff;
    box-shadow: 0 2px 4px rgba(90, 90, 90, 0.5);
}

.dashboardGroup:hover {
    background-image: url(/web3/build/admin/images/downArrowBig.png);
}

.dashboardGroup h5 {
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
}

table.dashboardSummary {
    margin: 0 0 15px;
}

table.dashboardSummary td,
table.dashboardSummary th {
    border-bottom: 1px solid #dfe1e4;
}

table.dashboardSummary th {
    font-weight: normal;
    text-align: right;
}

table.dashboardSummary td.count {
    width: 25px;
    text-align: right;
    font-size: 14px;
    font-weight: bold;
    color: #a10d25;
}

table.dashboardSummary td.count.lowPriority {
    color: inherit;
}

table.dashboardSummary td.runtime {

}

table.dashboardSummary th a {
    text-decoration: none;
}

span.zero {
    color: #8f8f8f;
}

table.dashboardSummary.site td {
    font-size: 14px;
    font-weight: bold;
}

.revenueSiteCount {
    text-align: center;
}

.siteCount {
    text-align: right;
}

table.dashboardSummary th.bigLabel {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
}

table.dashboardForecast {
    font-size: 14px;
    font-weight: bold;
}

table.dashboardForecast td {
    color: #a10d25;
    text-align: right;
}

table.dashboardForecast td,
table.dashboardForecast th {
    border-bottom: 1px solid #dfe1e4;
}

th.forecastHead {
    background: #f6f7f9;
    font-size: 12px;
    text-align: left;
}

th.bumpRight,
td.bumpRight {
    text-align: right;
}

.pressSheetByShipByCountsContainer-controls {
    margin-bottom: 20px;
}

.pressSheetByShipByCountsContainer-controls.loading {
    visibility: hidden;
    pointer-events: none;
}

select.dashboardFilter {
    width: 180px;
}

.dashboardWidget {
    margin: 0 0 20px;
}

.dashboardWidget h3,
table.resultsList tr.dashboardSection td {
    padding: 10px;
    margin: 0;
    font: normal bold normal 14px/14px "Lucida Grande", Verdana, Arial, "Bitstream Vera Sans", sans-serif;
    color: #ffffff;
    background: #506986 url("/web3/build/admin/images/rowSeparator.png") repeat-x scroll bottom left;
}

.dashboardWidget h3 strong,
table.resultsList tr.dashboardSection td strong {
    color: #ffffff;
}

.dashboardWidget table tr {
    background-color: #ffffff;
}

.dashboardWidget table.resultsList table.itemInOrder tr:hover {
    background-color: transparent;
}

table.resultsList tr.dashboardSection td a {
    display: inline;
    color: inherit;
}

tr.plannedShipment {
    background: #eff0f1 url(/web3/build/admin/images/psHeaderBg.png) repeat-x 0 0;
}

tr.plannedShipment td {
    padding: 1px;
    background: transparent;
}

tr.notInSameQueue {
    background: #f6f7f9 url(/web3/build/admin/images/notInQueueBg.png) repeat 0 0;
}

div.currentQueue {
    padding: 0 0 0 20px;
    background: transparent url(/web3/build/admin/images/information.png) no-repeat 0 0;
}

.dashboardWidget table.resultsList tr:hover {
    background-color: #ffffff;
}

.dashboardWidget table.resultsList tr.hasIssue:hover {
    background-color: #f5fc89;
}

.dashboardWidget table.resultsList tr.hardProof td.dashboardProof div.proofType {
    padding-right: 16px;
    background: transparent url(/web3/build/admin/images/hardProof.png) no-repeat 100% 0;
}

.dashboardWidget table.resultsList tr td.dashboardTurnTime {
    padding-left: 0;
    padding-right: 0;
}

.dashboardWidget table.resultsList tr.hardProof {
    background-color: #eeffc6;
}

.dashboardWidget table.resultsList tr.rushJob {
    background-color: #fff6ee;
}

.dashboardWidget table.resultsList tr td.dashboardTurnTime div {
    padding-right: 18px;
    margin-right: 2px;
    white-space: nowrap;
}

.dashboardWidget table.resultsList tr.rushJob td.dashboardTurnTime div {
    background: transparent url(/web3/build/admin/images/fire.png) no-repeat 100% 0;
}

.dashboardTurnTime {
    font-weight: bold;
}

.psGroup {
    float: left;
    height: 37px;
    background: transparent url(/web3/build/admin/images/psGroupBg.png) no-repeat 0 0;
}

.psNumber {
    float: left;
    width: 60px;
    padding: 10px 0 0 28px;
    background: transparent url(/web3/build/admin/images/truck.png) no-repeat 5px 10px;
}

.psDate {
    float: left;
    width: 110px;
    padding: 10px 0 0;
}

.psDate span {
    font-weight: bold;
}

.psService {
    float: left;
    width: 290px;
    height: 27px;
    padding: 10px 0 0;
    background: transparent url(/web3/build/admin/images/psGroupBg-right.png) no-repeat 100% 0;
}

.psService span {
    font-weight: bold;
}

.psHeaderOrderInfo {
    float: left;
    width: 290px;
    padding: 10px 0 0 20px;
}

.psFcName {
    float: right;
    width: 120px;
    padding: 10px 10px 0 0;
    text-align: right;
}

table.resultsList td div a.headerOrderNumber {
    display: inline;
}

table.resultsList tr.related td {
    color: #9c9ca2;
    background-color: #eef0f3;
}

a.jNumber {
    font-weight: bold;
    white-space: nowrap;
}

.dashQueue {
    color: #5f5f5f;
}

h3.withTip {
    margin: 0;
    padding-bottom: 10px;
}

/* lf operation sort  */

.ui-placeholder-highlight {
    height: 36px;
    background: #f8f9ee;
}

.operationName {
    font-size: 12px;
    padding: 0 0 10px;
}

.settingsGroup {
    padding: 5px 0 1px 20px;
    border-left: 1px solid #dfe1e4;
}

.tipInfo {
    padding-left: 20px;
    margin-bottom: 30px;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    border-radius: 4px;
    border-top-left-radius: 0;
    background: #eceef1 url("/web3/build/admin/images/lightGradient.png") repeat-x scroll 0 0;
}

.tipInfo.gridStyle {
    float: none;
    width: auto;
    padding-left: 12px;
}

p.tipControl {
    float: left;
    padding: 8px;
    margin: 0;
    border-right: 1px solid #dfe1e4;
}

p.tipControl.last {
    border: none;
}

p.tipControl.check {
    padding: 4px 0 8px;
    border: none;
}

table.tipControl {
    margin: 0;
}

table.tipControl td {
    padding: 6px 8px 8px;
    border-right: 1px solid #dfe1e4;
}

table.tipControl td:last-child {
    border: 0;
}

table.tipControl td.tipName {
    width: 80px;
}

.tipViewerCompact {
    height: 120px;
    padding: 5px 10px;
    margin: 0 0 10px;
    overflow: auto;
    border: 1px solid #b2b3b6;
    border-radius: 8px;
}

/**** OBJECT LIST ****/
#objectMenu {
    margin-bottom: 20px;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    border-radius: 4px;
    background-color: #ffffff;
}

.objectListFilter {
    height: 37px;
    padding: 11px 5px 0 15px;
    margin: 0;
    font-size: 11px;
    font-weight: normal;
    border-bottom: 1px solid #8d8f92;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: transparent url("/web3/build/admin/images/lightGradient.png") repeat-x scroll 0 0;
}

.objectListFilter a {
    padding: 3px 9px;
    margin-right: 3px;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 8px;
}

.objectListFilter a.on {
    border-color: #dadde3;
    background-color: #ffffff;
}

#objectList ul {
    list-style: none;
    margin: 0;
    padding: 10px 0;
}

#objectList ul li {
    display: block;
    margin: 0;
    padding: 0;
}

#objectList ul li a,
#objectList ul li a:visited {
    display: block;
    padding: 5px 5px 5px 18px;
    color: #5f5f5f;
    font-size: 11px;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/objectListBullet.png") no-repeat scroll 5px 0;
}

#objectList ul li a:hover,
#objectList ul li.readOnly a:hover {
    color: #506986;
}

#objectList ul li a.selected {
    color: #506986;
    background: #e4e6e9 url("/web3/build/admin/images/leftNavBullet.png") no-repeat scroll 0 -3px;
}

#objectList ul li.readOnly a,
#objectList ul li.readOnly a:visited {
    color: #9d9d9d;
}

.objectListPaging {
    padding: 0 10px 20px;
    text-align: center;
}

a.pager {
    padding: 2px 3px;
    text-decoration: none;
    border: 1px solid #e4e6e9;
    background-color: #f6f7f9;
}

a.pager.next {
    float: right;
}

a.pager.prev {
    float: left;
}

/**** GENERAL TABLE ****/
table caption {
    padding-bottom: 18px;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
}

table tr th,
table tr td {
    padding: 5px;
    text-align: left;
}

.htmlPreviewInList {
    max-height: 40px;
    overflow: auto;
}

.htmlPreviewInList p {
    margin-bottom: 5px;
}

.listPanelEdit a {
    text-decoration: none;
}

.block-link {
    display: block;
}

/**** FORM LAYOUT ****/
table.flush {
    margin: 0;
}

table.adminFields {
    /* font-size: 11px; */
    width: 100%;
}

table.adminFields th,
table.adminFields td {
    border-bottom: 1px solid #efefee;
}

table.adminFields.vertical {
    margin-bottom: 0;
}

table.adminFields.vertical td {
    padding-right: 20px;
    vertical-align: middle;
}

table.adminFields.vertical th {
    width: auto;
}

#accountsTable th:first-child {
    min-width: 164px;
}

tr.pendingDelete {
    font-style: italic;
    color: #aaaaaa;
    background: #eeeeee;
}

.prepressTechField {
    padding-top: 10px;
    padding-right: 15px;
}

table.adminFields th,
.fadedFieldName {
    color: #9c9ca2;
    font-weight: normal;
    text-align: left;
    width: 25%;
    max-width: 180px;
    min-width: 150px;
    padding: 10px 20px 5px 0;
}

.highlightContainer table.adminFields th {
    width: 30%;
    min-width: 170px;
    max-width: 190px;
}

table.adminFields.notes td {
    background: none;
    border-bottom: 1px solid #dfe1e4;
}

table.adminFields tr.static th {
    padding: 8px 20px 5px 0;
}

table.adminFields tr.static td tr th {
    padding: 10px 20px 5px 0;
}

table.adminFields tr.right th {
    text-align: right;
}

table.adminFields tr.objectName th {
    padding: 14px 20px 5px 0;
}

table.adminFields td {
    padding: 4px 0;
}

table.adminFields tr.short td input {
    width: 80px;
}

table.adminFields tr.medium td input {
    width: 150px;
}

table.adminFields tr td input[type=checkbox],
table.adminFields tr td input[type=radio],
table.adminFields tr td input[type=file] {
    width: auto;
}

table.adminFields tr.required th {
    color: #a10d25;
}

table.adminFields tr.required td input,
table.adminFields tr.required td textarea,
table.adminFields tr.required td select,
input.required,
textarea.required,
select.required {
    border-color: #a10d25;
}

table.adminFields tr.objectName td input {
    font-size: 20px;
}

.orderPricing tr.oneRow th {
    border: none;
}

table.resultsList table.adminFields tr th {
    border-right: none;
}

table.resultsList table.adminFields tr:hover {
    background: inherit;
}

table.resultsList table.configureGrid.adjustments tr th {
    border-right: none;
}

table.resultsList table.configureGrid td {
    padding: 10px 5px 6px 0;
    vertical-align: inherit;
}

table.configureGrid tr.integrationServerRecord td {
    height: 22px;
}

table.resultsList tr.homeRow td {
    font-weight: bold;
    background-color: #ffffff;
}

table.resultsList tr.homeRow td a {
    padding: 0 0 2px 25px;
    background: transparent url(/web3/build/admin/images/homeIcon.png) no-repeat 0 0;
}

table.resultsList tr td.short input {
    width: 75px;
}

table.adminFields.orderPricing th {
    width: auto;
}

table.adminFields.orderPricing th.labels {
    width: 260px;
}

table.adminFields.orderPricing th.price {
    width: 70px;
    padding-right: 10px;
}

table.adminFields.orderPricing th.extras {
    padding-right: 0;
}

table.adminFields.orderPricing {
    width: 100%;
    margin: 0;
}

.discountSummary {
    margin: 10px 0 0 170px;
}

.discountSummary p {
    margin-bottom: 5px;
    font-style: italic;
}

a.itemLedgerAccount {
    float: right;
    text-decoration: none;
}

a.itemLedgerAccount.orderCode {
    margin-left: 20px;
    width: 80px;
    text-align: right;
}

/** edit ledger accounts **/
#ledgerAccountTable td {
    vertical-align: middle;
}

tr.generalOrderAccount td {
    font-weight: bold;
    background-color: #f6f7f9;
}

tr.generalOrderAccount td input {
    font-weight: bold;
}

table.adminFields td.laName input {
    width: 120px;
}

table.adminFields td.laCode input {
    width: 90px;
}

table.adminFields td.laSelector select {
    width: 30px;
    font-size: 10px;
}

td.laSelector {
    position: relative;
}

div.inventoryAlert,
div.inventoryAlert a {
    color: #cc0000;
}

div.backorderedVariant a {
    padding-left: 16px;
    background: transparent url(/web3/build/admin/images/alert.png) 0 0 no-repeat;
}

.adminFieldsCheckbox {
    padding: 0 0 10px 120px;
}

.adminFieldsCheckbox.dependent {
    padding-left: 140px;
}

.fieldNotes {
    margin: 0;
    padding-top: 10px;
    font-size: 10px;
}

.metaNote {
    padding: 5px 0 0 5px;
    font-style: italic;
    color: #9c9ca2;
}

.editableInfo {
    padding: 0 0 5px;
}

.quickAddHolder {
    display: inline;
}

a.quickAdd {
    padding: 5px 5px 5px 20px;
    color: #71ab47;
    cursor: pointer;
    background: transparent url(/web3/build/admin/images/add.png) 0% 50% no-repeat;
}

a.quickAdd.cancel {
    color: #a10d25;
    background-image: url(/web3/build/admin/images/cancel.png);
}

a.quickAdd.logAction {
    background-image: url(/web3/build/admin/images/balloon.png);
}

a.quickAdd.variant {
    display: block;
    width: 120px;
    margin-top: 3px;
}

.quickAddField {
    padding: 5px 0;
}

a.quickAdd.charge {
    background-image: url(/web3/build/admin/images/moneyAdd.png);
}

a.quickAdd.cc-charge {
    background-image: url(/web3/build/admin/images/creditCard.png);
}

a.quickAdd.capture,
a.quickAdd.credit,
a.quickAdd.void,
a.quickAdd.charge {
    margin-right: 10px;
}

a.quickAdd.capture {
    display: inline;
    background-image: url(/web3/build/admin/images/dollarSign.png);
}

a.quickAdd.credit,
a.quickAdd.cc-credit {
    display: inline;
    color: #a10d25;
    background-image: url(/web3/build/admin/images/moneyDelete.png);
}

a.quickAdd.cc-credit {
    background-image: url(/web3/build/admin/images/creditCardMinus.png);
}

a.quickAdd.void,
a.quickAdd.cc-void {
    display: inline;
    color: #a10d25;
    background-image: url(/web3/build/admin/images/moneyDeleteAll.png);
}

a.quickAdd.cc-void {
    background-image: url(/web3/build/admin/images/creditCardMinusAll.png);
}

a.quickAdd.upload {
    background-image: url(/web3/build/admin/images/arrowUp.png);
}

.quickAdd.printer {
    background-image: url(/web3/build/admin/images/printer.png);
}

.shipmentControls {
    float: right;
    width: 200px;
}

a.shipIt {
    padding: 6px 10px 6px 22px;
    color: #000000;
    font-weight: bold;
    text-decoration: none;
    border: 1px solid #b2b3b6;
    border-radius: 6px;
    background: #eafbd7 url(/web3/build/admin/images/gear.png) 2px 4px no-repeat;
}

a.addPackage {
    display: block;
    width: 115px;
    margin-top: 3px;
    padding: 5px 5px 5px 20px;
    color: #71ab47;
    background: transparent url(/web3/build/admin/images/packageAdd.png) 0% 50% no-repeat;
}

a.paperAction {
    padding: 5px 5px 5px 20px;
    background: transparent url(/web3/build/admin/images/pageText.png) 0% 50% no-repeat;
}

tr.totals td {
    border-top: 3px solid #e3e4e5;
}

.transactionAdjust th {
    background: none;
}

.actionArea.transactionAction {
    width: 510px;
}

.actionArea textarea.adjustmentReason {
    height: 60px;
}

.actionArea textarea.qtyAdjustmentReason {
    height: 60px;
    width: 200px;
}

.transSuccess,
.transFail {
    padding: 0 0 0 20px;
    background: transparent 0 0 no-repeat;
}

.transSuccess { background-image: url(/web3/build/admin/images/save.png); }
.transFail { background-image: url(/web3/build/admin/images/exclamation.png); }

.trannyHistory {
    float: right;
    text-decoration: none;
}

table.configureGrid.adjustments tr:hover {
    background: inherit;
}

table.configureGrid.adjustments tr.totals:hover {
    background: #ffffff;
}

table.configureGrid.adjustments th {
    background-color: transparent;
    font-weight: bold;
    color: #5f5f5f;
}

.configureGrid td.adjustmentItem {
    width: 175px;
}

.configureGrid td.adjustmentItem select {
    width: 160px;
}

.configureGrid td.adjustmentAmount {
    width: 75px;
    white-space: nowrap;
}

.configureGrid td.adjustmentAmount input {
    width: 58px;
}

.configureGrid td.adjustmentReason {
    width: 190px;
}

.configureGrid td.adjustmentReason input {
    width: 180px;
}

.configureGrid td.adjustmentAction {
    width: 50px;
}

.configureGrid tr.totals td {
    font-size: 12px;
    font-weight: bold;
    background-color: #ffffff;
}

.configureGrid.adjustments tr.splitTax,
.configureGrid.adjustments tr.splitTax:hover {
    background: #ffdada;
}

/**** LEDGER ****/
table.ledger {}

table.ledger td,
table.ledger th {
    padding: 5px 8px;
    border-right: 1px solid #f6f7f9;
    background: transparent url("/web3/build/admin/images/rowSeparator.png") repeat-x scroll bottom left;
}

table.ledger td:first-child,
table.ledger th:first-child {
    border-left: 1px solid #f6f7f9;
}

table.ledger.thisOrder tr:nth-child(odd) {
    background-color: #f6f7f9;
}

table.ledger tr.ledgerTotals {
    font-weight: bold;
    background-color: #eeeeee;
}

.money {
    text-align: right;
    white-space: nowrap;
}

.viewOptions {
    height: 40px;
}

.viewOptions a {
    float: left;
    padding: 8px 10px;
    text-decoration: none;
}

.viewOptions a.on {
    color: #333333;
    border-radius: 8px;
    box-shadow: inset 0 1px 2px rgba(90, 90, 90, 0.5);
    background: #eef0f3;
}

table.batchTotals {
    font-size: 14px;
    font-weight: bold;
}

table.batchTotals td {
    background: #ffffff;
    border-bottom: 1px solid #f6f7f9;
}

tr.batchOrderSummary {
    font-weight: bold;
    background: #ffffff;
}

tr.batchOrderSummary.forUser,
tr.batchOrderSummary.forDivision {
    background: #eef0f3;
}

tr.batchOrderSummary td {
    padding: 10px 8px;
    border-right-color: transparent;
    vertical-align: top;
}

td.termsType {
    color: #5e0614;
}

.userSubtotalIcon {
    float: right;
    width: 16px;
    height: 16px;
    background: transparent url(/web3/build/admin/images/user.png) 0 0 no-repeat;
}

.divisionSubtotalIcon {
    float: right;
    width: 16px;
    height: 16px;
    background: transparent url(/web3/build/admin/images/divisions.png) 0 0 no-repeat;
}

/**** FROM EMAIL ****/
.fromEmail {
    padding: 8px 20px 8px 8px;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #dadada;
    background-color: #ffffff;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}

.fromEmail.editable {
    padding: 0;
}

.fromEmail.cancelMode {}

.fromEmail a.edit,
.fromEmail div.readOnly {
    display: block;
    padding: 8px 20px 8px 8px;
    background: transparent url(/web3/build/admin/images/pencil.png) 97% 50% no-repeat;
}

.fromEmail span {
    font-weight: normal;
    color: #333333;
}

input.fromEmailAddress {
    width: 95%;
}

.verificationStatus {
    padding: 2px 8px;
    font-weight: bold;
}

.sesEmail {
    padding: 3px 0 3px 20px;
    background: transparent no-repeat;
}

.sesEmail.verified { background-image: url(/web3/build/admin/images/verified.png); }
.sesEmail.pending { background-image: url(/web3/build/admin/images/spinner_small.gif); }
.sesEmail.notRequested { background-image: url(/web3/build/admin/images/verified-unknown.png); }
.sesEmail.failed { background-image: url(/web3/build/admin/images/alert.png); }

/**** SEARCH DROP DOWN *****/
div.searchDD {
    clear: both;
    width: 260px;
    height: 26px;
    margin: 5px 0 0;
    border: 1px solid #5f5f5f;
    border-radius: 6px;
}

div.searchDD input.searchField {
    float: left;
    width: 184px;
    padding-left: 16px;
    border: none;
    background: white url("/web3/build/admin/images/bullet-green.png") 0 4px no-repeat;
}

div.searchDD input.searchField.searching {
    color: #aaaaaa;
    background-image: url("/web3/build/admin/images/bullet-white.png");
}

a.clearDD,
a.dropDD {
    position: relative;
    top: 4px;
    text-decoration: none;
}

a.clearDD {
    margin-right: 5px;
}

div.searchDDList {
    max-height: 140px;
    width: 260px;
    overflow: auto;
    display: none;
    background-color: #ffffff;
    border: 1px solid #5f5f5f;
}

.searchDDList ul {
    list-style: none;
    margin: 0;
}

.searchDDList ul li {
    padding: 0;
    margin: 0;
}

.searchDDList ul li a {
    display: block;
    padding: 3px 5px 3px 10px;
    color: #5f5f5f;
    border-bottom: 1px solid #dfe1e4;
    text-decoration: none;
}

.searchDDList ul li a:hover {
    color: #506986;
    background-color: #dfe1e4;
}

/**** RESULTS TABLE ****/
table.resultsList {
    width: 100%;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    border-radius: 4px;
    border-collapse: separate;
    border-spacing: 0;
    border-style: solid;
}

.resultsList td,
.resultsList th {
    padding: 8px;
    text-align: left;
    vertical-align: top;
    background: transparent url("/web3/build/admin/images/rowSeparator.png") repeat-x scroll bottom left;
}

table.resultsList.stripeIt tr:nth-child(even) {
    background-color: #f2f2f4;
}

.resultsList th {
    border-right: 1px solid #dfe1e4;
    background: #e3e4e7 url("/web3/build/admin/images/grayGradient.png") repeat-x scroll top left;
}

.resultsList th.sortable {
    border-right: none;
    padding: 0;
}

.resultsList th.sortable a {
    border-right: 1px solid #dfe1e4;
    display: block;
    padding: 8px;
}

.resultsList th.sortable a.sorted {
    border-right: 1px solid #f6f7f9;
    background: transparent url("/web3/build/admin/images/darkGradient.png") repeat-x scroll top left;
}

table.resultsList tr.required td input {
    border-color: #a10d25;
}

table.resultsList td a {
    display: inline-block;
}

.hiddenProduct {
    color: #9c9ca2;
    font-style: italic;
}

table.resultsList tr:hover,
table.resultsList.stripeIt tr:hover {
    background-color: #ffffff;
}

table.resultsList tr.transactionControls {
    background-color: #eaebed;
}

table.resultsList tr.transactionControls:hover {
    background-color: #eaebed;
}

table.list-table tr.transactionControls {
    background-color: #eeeeee;
}

table.list-table tr.transactionControls:hover {
    background-color: #eaebed;
}

table.resultsList tr.inactive {
    background: #e6e8e8;
    color: #8d8f92;
}

table.resultsList tr.hasIssue,
table.resultsList.stripeIt tr.hasIssue {
    background: #f5fc89;
}

table.itemInOrder tr,
table.itemInOrder tr:hover {
    background: none;
}

table.resultsList tr.isClosed {
    color: #9c9ca2;
    font-style: italic;
}

h3.archivedHeader {
    padding: 6px 0 4px 35px;
    background: #efefef url(/web3/build/admin/images/wooden-box-24.png) no-repeat 5px 50%;
}

table.resultsList tr.demoOrder {
    color: #9c9ca2;
}

div.demoOrder {
    padding: 2px 0 2px 20px;
    white-space: nowrap;
    background: transparent url("/web3/build/admin/images/lock.png") no-repeat scroll 0 0;
}

table.resultsList tr.needsApproval,
table.resultsList.stripeIt tr.needsApproval {
    background-color: #f7dede;
}

table.resultsList tr.needsAdjustment,
table.resultsList.stripeIt tr.needsAdjustment {
    background-color: #eafbd7;
}

table.resultsList tr.onHold {
    color: #9c9ca2;
    background: #f2f2f4;
}

.issueIcon,
a.approvalFlag,
a.adjustmentIcon {
    display: block;
    text-decoration: none;
    white-space: nowrap;
    padding: 2px 0 2px 20px;
    background: transparent url("/web3/build/admin/images/alert.png") no-repeat scroll 0 0;
}

a.approvalFlag {
    background-image: url(/web3/build/admin/images/approvalFlag.png);
}

a.adjustmentIcon {
    background-image: url(/web3/build/admin/images/adjustmentNeeded.png);
}

.issueMessage h3 {
    margin: 0 0 10px;
}

#offeringVariantInfoContainer .issueIcon {
    white-space: normal;
}

a.adjustmentFlag {
    display: inline-block;
    margin: 7px 0 0 20px;
    padding: 3px 24px 1px 0;
    font-size: 12px;
    font-weight: normal;
    font-family: "Proxima Nova Soft W03 Reg", Helvetica, Arial;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/inBalance.png") no-repeat scroll 100% 50%;
}

a.adjustmentFlag.on {
    color: #a10d25;
    font-weight: bold;
    background-image: url(/web3/build/admin/images/adjustmentNeeded.png);
}

.reworkItem {
    padding: 6px 4px 5px;
    color: #c94d96;
    margin: 4px 5px 5px 23px;
    line-height: 16px;
}

.reworkItem:hover {
    cursor: pointer;
    color: #506986;
}

.reworkItem div {
    display: inline-block;
    padding: 0;
    text-decoration: none;
}

.reworkItem div.reworkTimestamp {
    color: #aaaaaa;
    font-size: 9pt;
    line-height: 13pt;
    display: block;
}

.editReworkCodeButton {
    height: 28px;
}

.editReworkCodeButton a.editReworkCode {
    height: 18px;
    margin-right: -3px;
}

.orderItemAction {
    width: 220px;
    padding: 5px 10px;
    border-bottom: 1px solid #e2e2e2;
    background: #f6f7f9;
    line-height: 16px;
}

.orderItemAction:hover {
    background: #f3f3f3;
}

.orderItemAction a,
.orderItemAction span {
    display: block;
    padding: 2px 0 2px 25px;
    text-decoration: none;
    background: transparent no-repeat scroll 0 50%;
}

.orderItemAction a.editLedgerAccount {
    padding: 2px 5px;
}

.orderItemAction a.cancel { background-image: url(/web3/build/admin/images/cancel.png); }
.orderItemAction a.orderItemJobName { background-image: url(/web3/build/admin/images/pencil.png); }
.orderItemAction a.printWorkTicket { background-image: url(/web3/build/admin/images/workTicket.png); }
.orderItemAction a.producerLink { background-image: url(/web3/build/admin/images/findOrderIcon.png); }
a.jobProperties { background-image: url(/web3/build/admin/images/jobProperties.png); }
.orderItemAction a.cancelOrderItem { background-image: url(/web3/build/admin/images/cancelItem.png); }
.orderItemAction a.btnIcon_alert { background-image: url(/web3/build/admin/images/alert.png); }
.orderItemAction a.btnIcon_alert_off { background-image: url(/web3/build/admin/images/alert_off.png); }
.orderItemAction a.btnIcon_exclamation { background-image: url("/web3/build/admin/images/exclamation.png"); }
.orderItemAction a.btnIcon_exclamation_off { background-image: url("/web3/build/admin/images/exclamation.png"); filter: grayscale(100%); }
.orderItemAction a.qtyAdjust { background-image: url(/web3/build/admin/images/qtyAdjustIcon.png); }
.orderItemAction .isShipped { background-image: url(/web3/build/admin/images/truckShipIt.png); }

.orderItemAction a.addCustomerRework { background-image: url(/web3/build/admin/images/enableIcon.png); }
.orderItemAction a.addProof { background-image: url(/web3/build/admin/images/enableIcon.png); }

.orderItemAction a.createInternalRework { background-image: url(/web3/build/admin/images/productionRun-sm.png); }
.orderItemAction a.createInternalRework.disabled { opacity: 0.5; color: #898989; }
.orderItemAction a.mergeProductionRun { background-image: url(/web3/build/admin/images/merge.png); }
.orderItemAction a.editReworkCode { background-image: url(/web3/build/admin/images/editStatus.png); }
.orderItemAction a.markComplete { background-image: url(/web3/build/admin/images/checkOff.gif); }
.orderItemAction a.markIncomplete { background-image: url(/web3/build/admin/images/checkOn.gif); }
.orderItemAction .productionPlanDetails { background-image: url(/web3/build/admin/images/document-list.png); }

.productionPlanDetails .productionPlanDetails-count {
    /* override overly generic existing styles. can remove once that gets cleaned up. */
    display: inline;
    padding: 0;
}

td.qtyAdjustmentNumber {
    text-align: right;
    font-weight: bold;
}

.productionQtyNote {
    padding: 3px 0 0 25px;
    font-weight: bold;
    background: transparent url(/web3/build/admin/images/productionQtyNoteIcon.png) no-repeat scroll 4px 0;
}

.orderItemAction a.printByDate {
    background-image: url(/web3/build/admin/images/calendarIconDay.png);
    cursor: pointer;
}

.orderItemAction a.clearDate {
    position: absolute;
    background: transparent url("/web3/build/admin/images/cancelItem.png") no-repeat center center;
    width: 23px;
    height: 23px;
    text-indent: -9999px;
    top: 0;
    right: 0;
}

.orderItemAction img.ui-datepicker-trigger {
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
}

table.stockItemPricing {
    margin: 0;
    width: 100%;
}

table.stockItemPricing tr {
    border-bottom: 1px solid #e3e4e5;
}

table.stockItemPricing tr.netChange {
    background-color: #e3e4e5;
    font-weight: bold;
    font-style: italic;
}

p.stockItemPriceCallout {
    padding: 2px 4px 2px 20px;
    color: #333333;
    background: #f3fdb3 url(/web3/build/admin/images/arrowDownRight.png) no-repeat 0 0;
}

/*** production runs ***/
.productionRunContainer {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0 15px;
    margin-bottom: 20px;
    border-top: 4px solid #b6b6b647;
}

.productionRunContainer.cancelled {
    color: #aaaaaa;
    background: transparent url(/web3/build/admin/images/cancelledItemBg.png) no-repeat 100% -3px;
}

.productionRunContainer.cancelled .prStatusChart,
.productionRunContainer.cancelled .productionRunRightCol,
.productionRunContainer.cancelled .customerNotes,
.productionRunContainer.cancelled .statusHolder,
.productionRunContainer.cancelled .prWorkTicket,
.productionRunContainer.cancelled .prJobProperties,
.productionRunContainer.cancelled .prPrintBy,
.productionRunContainer.cancelled .prMergeInternalRework,
.productionRunContainer.collapsed .productionRunLeftCol,
.productionRunContainer.collapsed .productionRunRightCol {
    display: none;
}

.productionRunContainer.collapsed .productionRunJobNumber {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}

.productionRunContainer.collapsed {
    height: 46px;
}

.productionRunRightCol {
    float: left;
    width: 56%;
    box-sizing: border-box;
    padding-left: 10px;
}

.productionRunLeftCol {
    float: left;
    width: 44%;
    box-sizing: border-box;
    padding-right: 10px;
}

ul.inList {
    margin: 0;
}

ul.inList li {
    margin-left: 10px;
}

table.resultsList td a.inlineLinkToItem {
    display: inline;
}

div.paging {
    width: 50%;
    float: left;
    padding: 0 0 10px 20px;
}

div.paging a {
    display: inline-block;
    padding: 2px 4px;
    margin: 0 1px;
    border: 1px solid #dfe1e4;
    text-decoration: none;
    min-width: 25px;
    text-align: center;
}

div.paging div.noArrow {
    display: inline-block;
    padding: 2px 4px;
    margin: 0 1px;
    color: #dfe1e4;
    border: 1px solid #dfe1e4;
    text-decoration: none;
    min-width: 25px;
}

div.paging a.thisPage {
    background-color: #dfe1e4;
    color: #a10d25;
}

div.paging div.results {
    padding-right: 25px;
    display: inline;
    float: right;
}

table.resultsList tr td a.download {
    display: inline;
    margin-left: 5px;
    padding: 5px 0 5px 15px;
    color: #8ac683;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/download.png") no-repeat scroll -2px 4px;
}

table.itemInOrder {
    margin: 0;
}

table.itemInOrder th,
table.itemInOrder td {
    padding: 0 5px 2px 0;
    border: none;
    background: none;
}

table.itemInOrder th {
    font-weight: normal;
    color: #9c9ca2;
}

td.rightsRadio {
    width: 38px;
}

a.listActions {
    float: left;
    width: 38px;
    height: 24px;
    background: transparent url(/web3/build/admin/images/gear.png) no-repeat 50% 50%;
}

.listActionsBubble {
    position: absolute;
    width: 300px;
    padding: 0 0 0 10px;
    overflow: hidden;
    border-width: 14px 12px 22px 20px;
    border-style: solid;
    border-image: url(/web3/build/admin/images/actionBubbleBrdr.png) fill 14 12 22 20 stretch;
}

.listActionsBubble ul {
    list-style: none;
    margin: 0;
}

.listActionsBubble ul li {
    padding: 0 0 6px;
    margin: 0;
}

a.listAction {
    display: block;
    padding: 2px 2px 2px 24px;
    text-decoration: none;
    background: transparent no-repeat scroll 3px 3px;
}

.listActionsBubble ul li a:hover {
    background-color: #f6f7f9;
}

a.listAction.turnIssueFlagOn { background-image: url("/web3/build/admin/images/alert.png"); }
a.listAction.turnIssueFlagOff { background-image: url("/web3/build/admin/images/alert_off.png"); }
a.listAction.closeOrder { background-image: url("/web3/build/admin/images/orderClosed.png"); }
a.listAction.status { background-image: url("/web3/build/admin/images/editStatus.png"); }
a.listAction.setApproval { background-image: url("/web3/build/admin/images/approvalFlag.png"); }
a.listAction.setFC { background-image: url("/web3/build/admin/images/building.png"); }
a.listAction.setPick { background-image: url("/web3/build/admin/images/package.png"); }
a.listAction.setCategory { background-image: url("/web3/build/admin/images/categoryTree.png"); }
a.listAction.setLedgerAccount { background-image: url("/web3/build/admin/images/abacus.png"); }
a.listAction.setVisibility { background-image: url("/web3/build/admin/images/visibilityIcon.png"); background-position: 3px -23px; }
a.listAction.setInventoryAlert { background-image: url("/web3/build/admin/images/megaphone.png"); }

a.listAction_hoverable {
    transition: background-color 0.5s;
    background-color: rgba(0, 0, 0, 0);
}

a.listAction_hoverable:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.tag {
    padding: 1px 5px;
}

.tagList {
    float: left;
    list-style: none;
}

.tagList li {
    margin: 0 30px 5px 0;
}

.gang-tag {
    padding: 0 3px;
    background: #eeeeee;
    color: #858585;
    border: 1px solid #dddddd;
    border-radius: 3px;
    font-size: 9pt;
    display: inline-block;
    margin-left: 5px;
}

.downloadListAsCSV {
    float: right;
    text-decoration: none;
    padding: 5px 20px 5px 2px;
    margin: 0 0 5px 30px;
    background: transparent url(/web3/build/admin/images/csv.png) 100% 50% no-repeat;
}

.htmlPreviewInList {
    float: left;
    height: 15px;
    width: 140px;
    margin: 0 4px;
    overflow: hidden;
}

.htmlPreviewInList * {
    font-size: inherit;
    cursor: default;
}

/**** FILTERS ****/
div.filtersContainer {
    float: left;
    width: 50%;
    padding-right: 40px;
    margin-bottom: 20px;
}

div.filtersContainer.orderList {
    float: left;
    width: 50%;
    padding-right: 50px;
}

div.filtersContainer.users {
    float: left;
    width: 50%;
    padding-right: 40px;
}

div.filterGroup {
    float: left;
}

a.resetFilters {
    text-decoration: none;
    padding: 5px 5px 5px 20px;
    margin-right: 30px;
    background: transparent url(/web3/build/admin/images/arrowUndo.png) 0% 50% no-repeat;
}

a.resetUserFilters {
    display: block;
    text-decoration: none;
    padding: 5px 5px 5px 20px;
    margin: 10px 0 0 120px;
    background: transparent url(/web3/build/admin/images/arrowUndo.png) 0% 50% no-repeat;
}

.editFilters {
    display: block;
    padding: 5px 5px 5px 20px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/filter.png) 0% 50% no-repeat;
}

.editFilters.advanced {
    width: 200px;
    background-image: url(/web3/build/admin/images/add.png);
}

.editFilters.advanced.cancel {
    width: 200px;
    background-image: url(/web3/build/admin/images/delete.png);
}

.cancel.filters {
    display: block;
}

.advancedFilters {
    padding: 0;
}

div.actionArea.filters {
    width: 440px;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

div.listFilters {
    float: left;
    width: 940px;
    padding-bottom: 10px;
}

div.filter {
    padding: 0 0 10px;
}

div.filter.static {
    float: none;
    padding: 0 0 5px;
}

div.filterTitle {
    float: left;
    width: 118px;
    padding: 3px 0 0;
    color: #999999;
}

div.filterTitle.static {
    padding: 0;
}

div.filterTitleMeta {
    padding-bottom: 2px;
}

div.listFilters select,
div.listFilters input[type=text] {
    width: 200px;
}

.staticValue {
    padding: 4px 0 6px;
}

div.removeFilter {
    float: left;
    height: 35px;
}

div.removeFilter a {
    display: block;
    height: 15px;
    color: #a10d25;
    text-decoration: none;
    padding: 5px 5px 5px 20px;
    background: transparent url(/web3/build/admin/images/delete.png) 0% 50% no-repeat;
}

/**** FILTERS TABLE ****/
table.listFilters {
    width: 100%;
}

.listFilters td,
.listFilters th {
    padding: 2px 12px 2px 0;
    font-size: 11px;
    text-align: left;
    vertical-align: top;
}

.listFilters td select {
    width: 120px;
}

label.filterCheckbox {
    padding: 0 5px 0 0;
    cursor: pointer;
}

.searchCount {
    float: right;
    margin: 5px 10px 5px 0;
    font-weight: bold;
}

/**** ORDER DETAILS ****/
.orderCycler {
    float: right;
    margin: 10px 0 0;
}

a.orderCycle {
    float: left;
    padding: 15px;
    margin-left: 5px;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    background: transparent no-repeat 50% 50%;
}

a.orderCycle:hover {
    border-color: #cccccc;
    background-color: #e7e9ee;
}

a.orderCycle.prev { background-image: url(/web3/build/admin/images/orderCyclePrevious.png); }
a.orderCycle.prev:hover { background-image: url(/web3/build/admin/images/arrowLeft.png); }
a.orderCycle.next { background-image: url(/web3/build/admin/images/orderCycleNext.png); }
a.orderCycle.next:hover { background-image: url(/web3/build/admin/images/arrowRight.png); }

.approvalAlert {
    padding: 5px 5px 5px 22px;
    margin-right: 10px;
    font-size: 1.3em;
    font-weight: bold;
    background: #f7dede url(/web3/build/admin/images/approvalFlag.png) no-repeat 3px 7px;
}

.statusHolder {
    margin: 5px 0 20px;
}

.statusLabel {
    font-weight: bold;
}

.statusBox {
    width: 220px;
    height: 29px;
    background: #ffffff;
    border: 1px solid #dfe1e4;
    border-top-color: #eeeeee;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 8px 10px 20px rgba(246, 247, 249, 0.4) inset;
}

.statusBox a,
.statusBox div {
    float: left;
    width: 220px;
    height: 28px;
    padding: 4px 40px 0 10px;
    font-size: 14px;
    font-weight: bold;
    color: #a10d25;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/editStatus.png) 195px 6px no-repeat;
}

.statusBox div {
    background: none;
}

.statusBox a.cancelStatusChange {
    background: transparent url(/web3/build/admin/images/cancel.png) 195px 7px no-repeat;
}

.production-status-detail {
    display: inline-block;
    padding-left: 60px;
    font-weight: normal;
}

.production-status-detail a {
    text-decoration: none;
}

.csrStatus {
    float: right;
    padding-top: 20px;
}

.csrStatus a,
.csrStatus div {
    float: right;
    padding: 2px 20px 2px 5px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/csr-unassigned.png) 100% 50% no-repeat;
}

.csrStatus a.assigned,
.csrStatus div.assigned {
    background-image: url(/web3/build/admin/images/csr.png);
}

.csrStatus a.cancelCsrChange {
    background-image: url(/web3/build/admin/images/cancel.png);
}

#csrMessages {
    margin-left: 250px;
}

.orderShippedOn {
    margin: 10px 0 0;
    color: #9c9ca2;
}

a.customerName {
    float: left;
}

.customerUserDiscountGroup {
    float: left;
    margin-left: 10px;
    padding-left: 20px;
    overflow: visible;
    background: transparent url(/web3/build/admin/images/star.png) no-repeat 0 0;
}

.userDiscountGroup-list {
    padding-left: 15px;
    overflow: hidden;
    background: transparent url(/web3/build/admin/images/star-sm.png) no-repeat 0 2px;
    color: #888888;
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.userDiscountGroup-ticket {
    padding-left: 15px;
    overflow: hidden;
    background: transparent url(/web3/build/admin/images/star-sm.png) no-repeat 0 2px;
    color: #888888;
    max-width: 160px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.9em;
}

.shipDate {
    position: relative;
    float: left;
}

.shipDate_hasShipDate {
    padding-right: 20px;
}

.shipDate .shipBy {
    cursor: pointer;
    float: left;
    font-size: 14px;
    padding-right: 27px;
    background: transparent url("/web3/build/admin/images/calendarIconDay.png") no-repeat center right;
}

.shipDate_hasShipDate .shipBy {
    background: none;
    padding-right: 25px;
    padding-right: 5px;
}

.shipDate .clearDate {
    position: absolute;
    background: transparent url("/web3/build/admin/images/cancelItem.png") no-repeat center center;
    width: 23px;
    height: 19px;
    text-indent: -9999px;
    top: 0;
    right: 0;
    display: none;
}

.shipDate_hasShipDate .clearDate {
    display: block;
}

.shipDate img.ui-datepicker-trigger {
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
}

.shipmentShippedOn {
    clear: both;
    color: #8d8d8d;
}

a.viewLedger {
    float: right;
    padding: 0 0 1px 20px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/ledgerIcon.png) 0 0 no-repeat;
}

#siteOrderMetadata table {
    margin: 0;
}

#siteOrderMetadata th {
    padding: 0 5px 5px 0;
}

#siteOrderMetadata td {
    line-height: 1em;
    padding: 0 0 10px;
}

.orderItemRow,
.pShipmentRow {
    float: left;
    padding: 10px 0;
    width: 100%;
    background: transparent;
    border-bottom: 1px solid #efefee;
}

.pShipmentRow {
    height: 75px;
}

.pShipmentRow .lateShipmentTag {
    display: inline-block;
    background-color: #f14d4d;
    color: #ffffff;
    padding: 6px 0 0;
    width: 65px;
    height: 30px;
    margin-left: 10px;
    text-align: center;
    font-size: 12px;
}

.orderItemRow a.jobNumber {
    font-family: inherit;
    color: #506986;
    font-weight: bold;
}

.orderItemRow .jobTitleJobNumber {
    display: inline-block;
    margin: 0 5px;
}

.orderItemRow.itemIssueAlert {
    background: transparent url(/web3/build/admin/images/orderItemRowIssueAlertBg.png) repeat 0 0;
}

.orderItemRow.itemDataTeamAlert {
    background: transparent url(/web3/build/admin/images/orderItemRowDataTeamAlertBg.png) repeat 0 0;
}

.orderItemRow.itemPrepressAlert {
    background: transparent url(/web3/build/admin/images/orderItemRowPrepressAlertBg.png) repeat 0 0;
}

.orderItemRow.cancelled {
    color: #aaaaaa;
    background: transparent url(/web3/build/admin/images/cancelledItemBg.png) no-repeat 100% 0;
}

.orderItemRow.backordered .orderItemBasics {
    background: transparent url(/web3/build/admin/images/backorderedItemBg.png) no-repeat 100% 40%;
}

span.backorderedQty,
li.backorderedItem {
    color: #a10d25;
}

.orderItemStatusChart {
    height: 30px;
}

.orderItemRow.cancelled table {
    display: none;
}

.orderItemRow.cancelled a.jobNumber {
    color: #c3c4c9;
}

.orderItemDetails,
.pShipmentDetails {
    float: left;
    width: 100%;
    padding: 15px 10px 0;
    border-bottom: 1px solid #ffffff;
    box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.8), inset 0 1px 2px rgba(90, 90, 90, 0.5);
    background: transparent url(/web3/build/admin/images/orderItemDetailBg.png) 0 0;
}

.pShipmentsFooter {
    float: left;
    width: 100%;
    height: 1px;
    margin: 0 0 20px;
}

.orderItemsFooter {
    clear: both;
    overflow: hidden;
    padding: 10px 0 20px 12px;
}

#assignPrepressTechForAllJobs,
#setAllJobsToProductionReady {
    margin-left: 10px;
    float: right;
}

#setAllJobsToProductionReady .setAllJobsToProductionReady-button {}

#setAllJobsToProductionReady .spinner {
    visibility: hidden;
    vertical-align: middle;
}

#setAllJobsToProductionReady.loading .spinner {
    visibility: visible;
}

#setAllJobsToProductionReady.loading .setAllJobsToProductionReady-button {
    pointer-events: none;
    opacity: 0.5;
}

#printItemList {
    float: left;
}

a.jobNumber {
    display: block;
    padding: 5px 0 5px 23px;
    font-size: 15px;
    font-family: "Proxima Nova Soft W03 Bd", Helvetica, Arial;
    text-decoration: none;
    cursor: pointer;
    background: transparent url(/web3/build/admin/images/jobIcon.png) 0 10px no-repeat;
    transition: background-color 2s;
}

.orderItemRow.rowUpdated .jobNumber {
    background: #f3fdb3 url("/web3/build/admin/images/save.png") no-repeat 4px 7px;
}

.productionRunJobNumber {
    display: block;
    padding: 0 0 10px 2px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}

.prTag {
    font-weight: normal;
    margin-right: 30px;
    background-color: #ffffff;
}

.prTag.internalRework {
    padding: 4px 10px;
    border-bottom: 3px solid #e19cbf;
}

.prTag.customerRework {
    padding: 4px 10px;
    border-bottom: 3px solid #b9a4d9;
}

.prTag.proof {
    padding: 4px 14px;
    border-bottom: 3px solid #8fcee5;
}

.prStatusChart {
    position: relative;
    top: -5px;
}

.prStatusChart ul {
    float: right;
    margin: 0;
    list-style: none;
}

.prStatusChart li {
    float: left;
    margin-left: unset;
    padding: 0;
}

.prStatusChart li div {
    padding: 6px 0 0;
    height: 30px;
    font-size: 12px;
    white-space: nowrap;
    width: 67px;
    margin-right: 1px;
    text-align: center;
    font-weight: normal;
}

.prStatusChart li.yes div {
    background: #d3ecbd;
    color: #529e01;
    border-bottom: 3px solid #b0d7a6;
}

.prStatusChart li.no div {
    background: #f0f0f0;
    color: #c9c9c9;
}

.prStatusChart li.alert div {
    background: #faf2a4;
}

.prStatusChart li.hide {
    display: none;
}

.prStatusChart li input[type=checkbox] {
    margin: 0;
    cursor: pointer;
}

a.jobNumber.stock {
    background-image: url(/web3/build/admin/images/stockIcon.png);
}

.itemNameQuantity {
    float: left;
    width: 320px;
    padding: 0 0 0 30px;
    color: #8d8d8d;
}

.productionGroupLabel {
    width: 160px;
    margin-left: 5px;
    float: left;
    color: #8d8d8d;
}

.productionGroupHighlightedLabel {
    width: 160px;
    color: #f5a11a;
    margin-left: 5px;
    float: left;
}

.itemIssueAlert .itemNameQuantity,
.itemIssueAlert .itemVariants {
    background: transparent url(/web3/build/admin/images/alert.png) 0 0 no-repeat;
}

.itemTurnTime {
    float: left;
    width: 170px;
    padding: 0;
    color: #8d8d8d;
}

.itemVariants {
    float: left;
    padding: 0 10px 0 30px;
    color: #8d8d8d;
}

.itemLocation,
.itemSKU {
    float: left;
    width: 200px;
}

a.hasMetaInfo {
    float: left;
    padding: 0 18px 2px 2px;
    text-decoration: none;
    font-weight: bold;
    background: transparent url(/web3/build/admin/images/arrowExpand.png) 100% 0 no-repeat;
}

.prepressTech,
table.jobStatus {
    float: right;
    margin: 0;
}

.grid-14 {
    float: left;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    width: 14%;
}

table.jobStatus tr {

}

table.jobStatus td {
    padding: 0;
    text-align: center;
}

table.jobStatus td div {
    padding: 6px 0 0;
    height: 30px;
    font-size: 12px;
    white-space: nowrap;
    width: 65px;
    margin-right: 1px;
    text-align: center;
}

table.jobStatus td.complete div {
    cursor: pointer;
}

table.jobStatus td.complete label {
    cursor: pointer;
}

table.jobStatus td.no div {
    background: #f0f0f0;
    color: #c9c9c9;
}

table.jobStatus td.yes div {
    background: #d3ecbd;
    color: #529e01;
    border-bottom: 3px solid #b0d7a6;
}

table.jobStatus td.picked div {
    background: #ffffff;
    color: #529e01;
    border-bottom: 3px solid #b0d7a6;
}

table.jobStatus td.alert div {
    background: #faf2a4;
}

table.jobStatus td.hide {
    display: none;
}

table.jobStatus td input[type=checkbox] {
    margin: 0;
    cursor: pointer;
}

.pShipment {
    display: block;
    padding: 0 0 5px 23px;
    font-size: 15px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/truck.png) 0 2px no-repeat;
    font-family: inherit;
    color: #506986;
    font-weight: bold;
}

.pShipment .shipmentTitleShipmentNumber {
    display: inline-block;
    margin: 0 8px 0 5px;
}

.pShipment .shipmentTitleItemCount {
    display: inline-block;
    margin: 0 5px 0 0;
}

.pShipment.add {
    background: none;
    color: #948d95;
    padding: 0 0 0 29px;
}

.pShipment.add select {
    display: inline-block;
    width: 200px;
    height: 26px;
}

.pShipment.add button {
    padding-top: 5px;
    padding-bottom: 5px;
}

a.pShipment span.serviceName {
    color: #a10d25;
}

.pShipmentFC {
    float: left;
    width: 220px;
    padding: 0 0 0 30px;
    color: #8d8d8d;
}

.pShipmentRecipient {
    float: left;
    color: #8d8d8d;
}

.pShipmentRecipient span.isBlind {
    color: #a10d25;
}

h3.jobItemHeader {
    height: 30px;
    padding: 7px 5px 0 15px;
    margin: 0;
    font-family: Georgia, "Times New Roman", "Bitstream Charter", Times, serif;
    font-size: 18px;
    letter-spacing: 0.05em;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #8d8f92;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: transparent url("/web3/build/admin/images/grayGradient.png") repeat-x scroll 0 0;
}

/******************** ABSTRACT CALCULATOR CHOICES **************/
#quoteSelectorDiv {
    margin-bottom: 20px;
    font-size: 12px;
}

#quoteSelectorDiv div div {
    padding-left: 25px;
    border-left: 1px dotted #eeeeee;
    background: transparent url(/web3/build/admin/images/decendentQuoteIcon.png) no-repeat 0 0;
}

#quoteSelectorDiv a {
    display: block;
    margin: 5px 0;
    padding: 6px 5px;
    text-decoration: none;
    border-radius: 4px;
    -moz-border-radius: 4px;
    border: 2px solid #f6f7f9;
    background-color: #f6f7f9;
    transition: background-color 0.5s ease;
}

#quoteSelectorDiv a:hover {
    background-color: #ffffff;
}

#quoteSelectorDiv a.selected {
    font-weight: bold;
    color: #ffffff;
    border: 2px solid #557aa0;
    background-color: #557aa0;
}

span.activeBadge {
    float: right;
    position: relative;
    top: -2px;
    padding: 2px 7px 2px 22px;
    margin-left: 10px;
    color: #92d676;
    font-weight: bold;
    border-radius: 4px;
    background: transparent url(/web3/build/admin/images/activeQuote.png) 2px 3px no-repeat;
}

#quoteSelectorDiv a.selected span.activeBadge {
    background-color: #fbfbfc;
}

.userChoicesHeader {
    float: left;
    padding: 0 0 0 30px;
    color: #888888;
    background: transparent url(/web3/build/admin/images/jobSpecsDownBg.png) 0 0 no-repeat;
}

.jobPropertiesHeader {
    float: right;
    padding: 0 30px 0 0;
    color: #888888;
    background: transparent url(/web3/build/admin/images/jobSpecsRightBg.png) 100% 0 no-repeat;
}

#quoteControls {
    margin: 0 0 20px;
}

table.requoteValues {
    font-weight: bold;
}

span.negative {
    color: #cc0000;
}

.calculatorChoices {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 4px;
    background: #f6f7f9;
}

.calculatorChoices h3 {
    margin: 20px 0 10px;
}

.calculatorChoices .noEdit {
    font-weight: bold;
    background: #efefef;
}

.c-op-question {
    margin-top: 5px;
    font-weight: bold;
    font-style: italic;
}

.c-sub-answer {
    font-weight: normal;
    font-style: italic;
}

.optionWrapperOn {
    padding: 0 0 10px;
}

.optionWrapperOn.hidden {
    color: #999999;
}

label.config,
label.opQuestion,
label.top {
    display: block;
}

label.internal-only {
    color: #40b1c6;
}

.noEdit {
    padding: 3px 6px;
    border: 1px solid #cccccc;
}

.abstractChoices .noEdit {
    font-weight: bold;
    background: #f6f7f9;
}

.op {
    padding: 5px;
    background-color: #eeeeee;
}

.wasNotEditable {
    float: right;
    padding: 0 0 0 20px;
    font-weight: normal;
    font-style: italic;
    background: transparent url(/web3/build/admin/images/lock.png) no-repeat 0 0;
}

/***   */
.productionDetails {
    padding: 10px 10px 0;
    margin-bottom: 20px;
    border-left: 1px solid #dfe1e4;
    border-right: 1px solid #dfe1e4;
    border-bottom: 1px solid #dfe1e4;
    border-radius: 0 0 4px 4px;
}

.jobFilesContainer {
    padding: 10px 15px;
    margin: 0 0 20px;
    background: #ffffff;
    border: 1px solid #dfe1e4;
    border-top-color: #eeeeee;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 8px 10px 20px rgba(246, 247, 249, 0.4) inset;
}

.theFiles {
    width: 100%;
    float: left;
    clear: both;
    padding-top: 10px;
}

.fileRow.printFile {
    width: 100%;
}

.fileRow {
    float: left;
    clear: both;
    border-bottom: 1px solid #eeeeee;
}

.fileRow.inactive,
.fileRow.inactive a {
    background-color: #fafafa;
    color: #aaaaaa;
}

.fileRow:hover {
    background: #f6f7f9;
}

.fileName,
.fileNote,
.fileDate,
.deactivate,
.templateNote,
.fileDescription {
    float: left;
    padding: 6px 5px 3px;
}

.fileDescription {
    width: 238px;
}

.templateNote {
    width: 238px;
}

.proofFilesName {
    width: 60%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.proofFilesDate {
    width: 20%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.proofFilesDeactivate {
    width: 20%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fileName.printFile {
    width: 60%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fileDate.printFile {
    width: 20%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.deactivate.printFile {
    width: 20%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noFileUploaded {
    float: left;
    padding: 8px 5px;
    color: #aaaaaa;
    font-style: italic;
}

.fileName {
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileNote {
    background: #f5fc89;
}

.fileNote.noNote {
    background: none;
}

.fileDate {
    padding-top: 7px;
}

.deactivate {
    padding-top: 7px;
}

.fileLinkHolder {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

a.uploadedFile {
    text-decoration: none;
    display: block;
    padding: 0 0 4px 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    background: transparent url("/web3/build/admin/images/fileIcon.png") no-repeat scroll 0 0;
}

a.uploadedFile.proof {
    background-image: url(/web3/build/admin/images/fileProof.png);
}

a.noteLink {
    display: block;
    padding: 2px 0 4px 20px;
    background: transparent url("/web3/build/admin/images/note.png") no-repeat scroll 0 0;
}

a.noteLink.addNote {
    color: #6dc520;
    background: transparent url("/web3/build/admin/images/noteAdd.png") no-repeat scroll 0 0;
}

.theNote {
    float: left;
    width: 95%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 5px 20px;
    margin: 0 0 5px 25px;
    border-radius: 8px;
    border-top-right-radius: 0;
    background: #f5fc89 url(/web3/build/storefront/images/note.png) no-repeat scroll 2px 4px;
    border-radius: 8px;
}

a.previewPersonalization {
    float: right;
    padding: 0 0 4px 20px;
    background: transparent url(/web3/build/admin/images/view.png) no-repeat scroll 0 50%;
}

.actionArea.addFileNote {
    float: left;
    width: 400px;
    margin: 0 0 5px 20px;
}

input.fileNoteInput {
    width: 380px;
    margin-bottom: 10px;
}

a.cancelAction.inActionArea {
    float: right;
    padding: 0 0 5px 17px;
    margin-left: 20px;
    height: 15px;
    color: #5f5f5f;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/cancel.png) 0% 50% no-repeat;
}

#noteQuickView {
    position: absolute;
    width: 200px;
    padding: 25px 10px 10px;
    z-index: 9999;
    color: #ffffff;
    font-size: 12px;
    text-decoration: none;
    border-bottom-right-radius: 8px;
    background: transparent url(/web3/build/admin/images/noteQuickViewBg.png) 0 0 no-repeat;
}

table.fileManager {
    margin: 0;
    width: 100%;
    table-layout: fixed;
}

table.fileManager tr {
    border-bottom: 1px solid #eeeeee;
}

table.fileManager tr:hover {
    background-color: #f6f7f9;
}

table.fileManager.uploading tr:hover {
    background-color: transparent;
}

table.fileManager td {
    padding: 5px 10px 5px 5px;
}

table.fileManager tr.inactive td,
table.fileManager tr.inactive td a {
    color: #aaaaaa;
    background-color: #fafafa;
}

table.fileManager th {
    font-weight: normal;
    padding-bottom: 0;
}

.jobContainer {
    padding-left: 20px;
}

#largePreviewImageContainer {}

#largePreviewImageContainer img {
    border: 1px solid #555555;
    max-width: 100%;
    image-rendering: crips-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimizeQuality;
    image-rendering: high-quality;
}

.edocPageNumbers {
    padding: 0 15px;
    font-weight: bold;
    font-size: 14px;
}

/**** PRINT JOB TABLE ****/
a.print {
    display: block;
    width: 180px;
    padding: 0 2px 6px 25px;
    background: transparent url("/web3/build/admin/images/printer.png") no-repeat scroll top left;
}

a.print.frame {
    position: relative;
    top: -10px;
}

a.print.internationalForms {
    padding: 5px 5px 5px 20px;
    margin-top: 3px;
    background-position: 0 50%;
}

table.adminFields.orderJobSpecs caption {
    padding-bottom: 5px;
}

table.adminFields.orderJobSpecs th {
    width: 180px;
    padding: 5px 10px 2px 0;
}

table.adminFields.orderJobSpecs td {
    padding-left: 3px;
}

.orderJobSpecs tr.rushJob td {
    background-color: #fff6ee;
}

table.orderJobSpecs td.highlighter {
    background-color: #f3fdb3;
}

.orderJobSpecs tr.rushJob strong {
    display: block;
    padding-right: 20px;
    background: transparent url(/web3/build/admin/images/fire.png) no-repeat 100% 0;
}

.orderNumberTicket {
    font-size: 22px;
    line-height: 22px;
}

.printableTicket h3 {
    margin-bottom: 10px;
}

.printableTicket table.adminFields {
    font-size: 14px;
}

.printableTicket table.adminFields th {
    padding: 3px 10px 3px 0;
    color: inherit;
}

.customerNote {
    background: #ffe4e4;
    border: 1px solid #ed9999;
    padding: 6px 10px;
    border-bottom-color: #bb5858;
}

.printableTicket table.adminFields td {
    padding-top: 3px;
    padding-bottom: 3px;
    color: #000000;
}

input.writeInField {
    padding: 0;
    border-radius: 0;
    border: none;
    font-size: 12px;
    font-family: Arial, Helvetica;
}

div.qcCheckbox,
div.lftCheckbox {
    float: right;
    width: 15px;
    height: 15px;
    border: 1px solid #666666;
}

div.lftCheckbox {
    float: left;
    margin-right: 4px;
}

tr.blindShipHighlight td {
    padding-left: 2px;
    padding-right: 2px;
    background-color: #f3fdb3;
}

table.adminFields th.pShipDateLabel {
    vertical-align: bottom;
}

strong.pShipByDate {
    font-size: 18px;
}

.versionQuantity {
    float: left;
    width: 50px;
}

.totalQuantity {
    border-bottom: 1px solid #cccccc;
}

/**** ACTIVITY LOG ****/
.newAction {
    margin-bottom: 5px;
}

.actionArea {
    padding: 10px 15px;
    margin: 0 0 0 20px;
    border-radius: 12px;
    border-top-left-radius: 0;
    background: #edeff1;
}

.actionArea.flatTop {
    border-top-right-radius: 0;
}

.actionArea.status {
    margin: 0;
    padding: 20px 15px;
}

.actionArea {
    border: none;
    box-shadow: inset 0 1px 2px rgba(90, 90, 90, 0.5);
}

.actionArea.csr {
    margin-left: 250px;
}

.actionArea.noMargin {
    margin: 0;
}

.actionArea.noBg {
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    box-shadow: none;
    background: #ffffff;
}

.actionArea.approvalRules {
    margin-left: 0;
}

.actionArea.dimensions {
    padding: 20px 15px;
}

.actionArea.dimensions input {
    width: 45px;
}

.actionArea ul {
    list-style: none;
    margin: 0;
}

.actionArea ul li {
    padding: 2px 0;
    margin: 0;
}

.actionArea ul li a {
    display: block;
    padding: 2px 4px 2px 18px;
    text-decoration: none;
}

.actionArea ul li a:hover {
    background: #f6f7f9 url("/web3/build/admin/images/bullet-green.png") no-repeat scroll 0 1px;
}

.actionArea ul.shipmentProcessing {
    list-style: disc;
}

.actionArea ul.shipmentProcessing li {
    margin-left: 20px;
}

.actionArea textarea {
    height: 180px;
    margin-bottom: 10px;
}

.actionArea select {
    width: 200px;
    margin: 0 10px 0 0;
}

.actionArea.shippingService {
    margin: 0 0 10px;
}

.actionArea.shippingService select {
    width: 300px;
}

.actionArea.shippingService input[type=text] {
    width: 274px;
    margin-bottom: 10px;
}

#shippingServiceControl {
    margin-bottom: 20px;
}

.activityLog {
    padding: 10px 15px;
    margin: 0 0 20px;
    background: #ffffff;
    border: 1px solid #dfe1e4;
    border-top-color: #eeeeee;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 8px 10px 20px rgba(246, 247, 249, 0.4) inset;
}

.activities {
    max-height: 400px;
    overflow: auto;
}

.theActivity {
    margin: 0;
    padding: 10px 0;
}

.theActivity + .theActivity {
    border-top: 1px solid #e4e6e9;
}

.theActivity_internal {
    border-left: 8px solid #23abc3;
    padding-left: 8px;
}

.showInternalActivity_hide .theActivity_internal {
    display: none;
}

.showExternalActivity_hide .theActivity:not(.theActivity_internal) {
    display: none;
}

.timestamp {
    padding: 0 5px 5px 20px;
    color: #7f7f7f;
}

.theActivity .subject,
.actionArea .subject {
    font-size: 13px;
    padding: 0 5px 0 20px;
    color: #1e3a5c;
    background: transparent url("/web3/build/admin/images/balloon-small.png") no-repeat scroll 0 0;
}

.theActivity blockquote {
    font-family: helvetica, arial, sans-serif;
    padding: 0 5px 0 20px;
    color: #5f5f5f;
    background: transparent url("/web3/build/admin/images/quote.png") no-repeat scroll 0 0;
}

.theActivity blockquote p,
.theActivity blockquote ol,
.theActivity blockquote ul {
    margin: 0 0 10px;
}

.customerNotes {
    padding: 10px 15px;
    margin: 0 0 20px;
    background: #ffffff;
    border: 1px solid #dfe1e4;
    border-top-color: #eeeeee;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 8px 10px 20px rgba(246, 247, 249, 0.4) inset;
}

.customerNotes h3 {
    margin-bottom: 8px;
    text-shadow: none;
    font: 27px/26px "JournalRegular", helvetica, arial;
}

.reorder-info {
    padding: 0 0 4px 20px;
    margin-bottom: 10px;
    background: transparent url("/web3/build/admin/images/reorder-icon.png") no-repeat scroll 0 0;
}

.salesEstimateRef {
    color: #71ab47;
    font-size: 12pt;
}

/**** INTERNAL NOTES ****/
a.addINote {
    display: block;
    width: 180px;
    margin: 0 0 5px;
    padding: 2px 0 4px 20px;
    color: #71ab47;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/noteAdd.png") no-repeat scroll 0 0;
}

a.editINote {
    float: right;
    padding: 5px 0 5px 20px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/pencil.png) no-repeat scroll 0 50%;
}

.internalNote {
    padding: 10px 15px;
    margin: 0 0 20px;
    background: #f7fdb3;
    border: 1px solid #dfe1e4;
    border-top-color: #eeeeee;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 8px 10px 20px rgba(246, 247, 249, 0.4) inset;
}

.internalNote.userNote {
    background: #bdffff; /* old browsers */
}

.internalNote.ticket:before,
.internalNote.ticket:after {
    transform: none;
    box-shadow: none;
}

.internalNote.ticket {
    box-shadow: none;
}

.internalNote h3 {
    margin-bottom: 8px;
    text-shadow: none;
    font: 27px/26px "JournalRegular", helvetica, arial;
}

.noteBody {
    max-height: 180px;
    overflow: auto;
}

.internalNote.ticket .noteBody {
    font-size: 12px;
    color: #333333;
    max-height: none;
}

.noteBody p,
.internalNote ul,
.internalNote ol {
    margin-bottom: 8px;
}

.internalNote.ticket h3 {
    font: 16px/16px Arial, Helvetica, "Liberation Sans", FreeSans, sans-serif;
    font-weight: bold;
}

.highlightContainer {
    padding: 10px 15px;
    margin: 0 0 20px;
    background: #ffffff;
    border: 1px solid #dfe1e4;
    border-top-color: #eeeeee;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 8px 10px 20px rgba(246, 247, 249, 0.4) inset;
}

.customerNoteBody {
    font-family: "Proxima Nova Soft W03 Smbd", Helvetica, Arial;
}

.internalNote_quote {
    white-space: pre-wrap;
    padding-bottom: 20px;
}

.internalNote_quote .noteBody {
    max-height: none;
    overflow: visible;
}

.internalNote.estimating {
    background: #efefef;
}

.highlightContainer p {
    margin-bottom: 10px;
}

.productDetail .highlightContainer {
    min-height: 150px;
}

.internalOnly {
    background-color: #f5fc89;
}

/*** PLANNED SHIPMENTS ***/
.plannedShipmentAlerts a.editInPanel {
    float: left;
    font-weight: bold;
    font-size: 12px;
    text-decoration: underline;
}

.allocationAlert {
    float: left;
    height: 32px;
    margin-bottom: 10px;
    padding: 7px 10px 0 38px;
    font-weight: bold;
    font-size: 12px;
    background: transparent url(/web3/build/admin/images/alert-32.png) 0 0 no-repeat;
}

.adminFields.allocations tr td:first-child {
    font-weight: bold;
    padding-right: 4px;
}

a.remove.newPs {
    float: right;
    padding: 3px 0 2px 20px;
}

a.edit.newPs {
    float: right;
    padding: 3px 0 2px 20px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/pencil.png) no-repeat scroll 0 50%;
}

a.edit.newPs.disable {
    background-image: url(/web3/build/admin/images/disable.png);
}

table.pShipmentQuantityGrid th {
    padding: 0 5px 6px;
    vertical-align: bottom;
    font-size: 11px;
    border-right: 1px solid #eaebed;
    background: transparent url("/web3/build/admin/images/rowSeparator.png") repeat-x scroll bottom left;
}

table.pShipmentQuantityGrid td {
    padding: 6px 5px;
    border-right: 1px solid #eaebed;
    background: transparent url("/web3/build/admin/images/rowSeparator.png") repeat-x scroll bottom left;
}

table.pShipmentQuantityGrid td.itemName,
table.pShipmentQuantityGrid td.requestedQuantity {
    background-color: #f6f7f9;
}

table.pShipmentQuantityGrid td.itemName {
    font-size: 11px;
    color: #222222;
    vertical-align: bottom;
}

table.pShipmentQuantityGrid td.requestedQuantity {
    font-weight: bold;
}

#panelGridContainer {
    overflow: auto;
}

#panelGridContainer input:disabled {
    background: #efefef;
}

table.configureGrid.packages {
    margin: 0;
    width: 100%;
}

table.configureGrid.packages.edit {
    background: #edeff1;
}

table.configureGrid.packages td {
    padding-top: 8px;
    padding-bottom: 8px;
}

table.configureGrid.packages .right {
    text-align: right;
}

th.packageNumber,
td.packageNumber {
    width: 15px;
}

th.iconCol,
td.iconCol {
    width: 16px;
}

th.packDimensions,
td.packDimensions {
    width: 180px;
}

th.editRow,
td.editRow,
th.deleteRow,
td.deleteRow {
    width: 30px;
}

th.trackingCol,
td.trackingCol {
    width: 150px;
}

th.labelCol,
td.labelCol {
    width: 100px;
}

input.packageSpec {
    width: 45px;
    padding: 5px;
}

td.packDimensions input.packageSpec {
    width: 35px;
}

.icon-package {
    display: block;
    width: 16px;
    height: 16px;
    background: transparent url("/web3/build/admin/images/package.png") no-repeat 0 0;
}

div.addressMovers {
    float: right;
    height: 1px;
    overflow: visible;
}

a.addressMover {
    float: left;
    margin: 0 0 0 20px;
    padding: 0 0 2px 18px;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/addressArrows.png") 0 0 no-repeat;
}

a.addressMover.up {}

a.addressMover.down {
    background-position: 0 -26px;
}

a.addressMover.swap {
    background-position: 0 -52px;
    padding-left: 26px;
}

/*** Product Categories ***/
.selectedCategories ul {
    margin: 0;
    list-style: none;
}

.selectedCategories ul li {
    margin: 0;
}

.selectedCategories ul li ul {
    border-left: 1px solid #cccccc;
}

.selectedCategories ul li ul li {
    margin: 3px 0 3px 15px;
}

.selectedCategories ul li span.selected {
    font-weight: bold;
}

.availableCategories ul {
    margin: 0;
    list-style: none;
}

.availableCategories ul li {
    margin: 0 0 4px;
}

.availableCategories ul li ul {
    border-left: 1px solid #cccccc;
}

.availableCategories ul li ul li {
    margin: 4px 0 4px 20px;
}

/*** SYSTEM PRODUCTS ***/
div.highlightContainer.noEdit {
    background: none;
}

.offeringTypeChoice {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    cursor: pointer;
}

div.offeringTypeChoice:hover,
div.offeringTypeChoice.selected {
    background: #ffffff;
}

div.offeringTypeChoice.selected {
    cursor: default;
}

.selected .offeringType {
    color: #5f5f5f;
}

.offeringType {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
    color: #506986;
}

.attributeTitle {
    margin: 0 0 10px;
}

input.attribute-title {
    width: 300px;
    margin: 0 5px;
}

.attributeTitle a.remove {
    margin-left: 5px;
}

.imageContainer {
    float: left;
    width: 100%;
}

a.productImage {
    float: left;
    width: 90%;
    padding: 0 0 4px 24px;
    background: transparent url("/web3/build/admin/images/image.png") no-repeat scroll 1px 3px;
}

a.deleteProductImage {
    float: right;
    width: 16px;
    height: 16px;
    margin: 0 0 0 20px;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/delete.png") no-repeat scroll 0 0;
}

a.editProductImage {
    float: right;
    width: 16px;
    height: 16px;
    margin: 0 0 0 20px;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/pencil-gray.png") no-repeat scroll 0 0;
}

a.editProductImage:hover {
    background: transparent url("/web3/build/admin/images/pencil.png") no-repeat scroll 0 0;
}

.productImageUploadWrapper {
    clear: both;
    overflow: hidden;
}

.productImageUploadWrapper a.upload {
    float: left;
}

.altTextWrapper {
    padding: 5px 0 10px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.altTextEdit {}

.altTextEdit_hasText {
    font-style: italic;
    color: #9c9ca2;
    text-decoration: none;
    border-bottom: 1px dashed transparent;
}

.altTextEdit_hasText:hover {
    border-bottom-color: #9c9ca2;
}

/* variants */
.stockSettings {
    float: right;
    margin: 0 10px 0 0;
    padding: 5px 10px 0 0;
    border-right: 1px solid #f6f7f9;
}

table.configureGrid th {
    background: #ffffff;
}

table.configureGrid th.attribute,
table.configureGrid td.attribute {
    font-weight: bold;
    width: 115px;
    position: relative;
}

table.configureGrid td.attribute input {
    width: 105px;
}

.configureGrid_variants {
    width: 100%;
}

table.configureGrid th.varSku,
table.configureGrid td.varSku {
    width: 105px;
}

table.configureGrid td.varSku input {
    width: 80px;
}

table.configureGrid th.varPrice,
table.configureGrid td.varPrice {
    width: 80px;
}

table.configureGrid td.varPrice input {
    width: 60px;
}

table.configureGrid th.varWarehouseManaged,
table.configureGrid td.varWarehouseManaged {
    width: 100px;
}

table.configureGrid th.varSegmentCode,
table.configureGrid td.varSegmentCode {
    width: 100px;
}

table.configureGrid td.varSegmentCode input {
    width: 70px;
}

table.configureGrid th.varLocation,
table.configureGrid td.varLocation {
    width: 100px;
}

table.configureGrid td.varLocation input {
    width: 70px;
}

table.configureGrid th.varInventory,
table.configureGrid td.varInventory {
    width: 55px;
    padding-left: 5px;
    border-left: 1px solid #cccccc;
    text-align: center;
}

table.configureGrid th.varWeight,
table.configureGrid td.varWeight {
    width: 45px;
    text-align: center;
}

table.configureGrid th.varEdit,
table.configureGrid td.varEdit {
    width: 30px;
}

table.configureGrid th.varSort,
table.configureGrid td.varSort {
    width: 30px;
}

table.configureGrid th.varRemove,
table.configureGrid td.varRemove {
    width: 30px;
}

table.configureGrid th.varSave,
table.configureGrid td.varSave {
    width: 40px;
}

.configureGrid_turnTimes {
    width: 100%;
    table-layout: fixed;
}

.configureGrid_turnTimes .edit,
.configureGrid_turnTimes .sort,
.configureGrid_turnTimes .remove {
    width: auto;
    text-align: center;
}

.configureGrid_turnTimes .edit a,
.configureGrid_turnTimes .sort a,
.configureGrid_turnTimes .remove a {
    display: inline-block;
}

.configureGrid_turnTimes .default {
    width: 60px;
    text-align: center;
}

.configureGrid_turnTimes .default a {
    width: 14px;
    height: 16px;
    display: inline-block;
}

.configureGrid_turnTimes .turnTimesInternal {
    width: 130px;
}

.configureGrid_turnTimes .turnTimesPublic {
    width: 130px;
}

.configureGrid_turnTimes .turnTimesFee {
    width: 120px;
}

.configureGrid_turnTimes .turnTimesQty {
    width: 65px;
    text-align: center;
}

a.inventoryEdit {
    display: block;
    text-decoration: none;
}

a.inventoryEdit:hover {
    text-decoration: underline;
}

table.configureGrid tr.disabledVariant,
table.configureGrid tr.disabledServer {
    background: #efefef;
    color: #cccccc;
}

ol.sortableGrid {
    margin: 0;
    padding: 0;
    list-style: none;
}

ol.sortableGrid li {
    margin: 0;
    padding: 0;
    list-style: none;
}

table.configureGrid td a.editVariant,
table.configureGrid td a.removeVariant,
table.configureGrid td a.cancelEditRow,
a.enableVariant,
a.enableServer,
a.disableServer {
    display: block;
    width: 20px;
    height: 16px;
    margin-bottom: 5px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/pencil-gray.png) no-repeat 0 1px;
}

table.configureGrid td a.editVariant {
    background-position: 0 0;
}

table.configureGrid td a.editVariant.on {
    background-image: url(/web3/build/admin/images/pencil.png);
}

table.configureGrid td a.removeVariant,
table.configureGrid td a.disableServer {
    background-image: url(/web3/build/admin/images/delete.png);
}

table.configureGrid td a.enableVariant,
table.configureGrid td a.enableServer {
    background-image: url(/web3/build/admin/images/enableIcon.png);
}

table.configureGrid td a.cancelEditRow {
    background-image: url(/web3/build/admin/images/cancelItem.png);
}

table.configureGrid.productMetaFields {
    width: 770px;
}

table.configureGrid.productMetaFields th.metadataQuestion {
    width: 310px;
    position: relative;
    padding: 5px 0;
}

table.configureGrid.productMetaFields th.metadataFieldType {
    width: 240px;
    position: relative;
    padding: 5px 0;
}

table.configureGrid.productMetaFields th.metadataAdminDisplay {
    width: 110px;
    position: relative;
    padding: 5px 0;
}

table.configureGrid.productMetaFields th.metadataEdit {
    width: 50px;
    position: relative;
    padding: 5px 0;
}

table.configureGrid.productMetaFields th.metadataRemove {
    width: 60px;
    position: relative;
    padding: 5px 0;
}

table.configureGrid.productMetaFields td.metadataQuestion {
    width: 310px;
    position: relative;
    padding: 5px 0;
}

table.configureGrid.productMetaFields td.metadataFieldType {
    width: 240px;
    position: relative;
    padding: 5px 0;
}

td.metadataFieldType select {
    display: block;
    width: 200px;
}

table.configureGrid.productMetaFields td.metadataAdminDisplay {
    width: 110px;
    position: relative;
    padding: 5px 0;
}

table.configureGrid.productMetaFields td.metadataEdit {
    width: 50px;
    position: relative;
    padding: 5px 0;
}

table.configureGrid.productMetaFields td.metadataRemove {
    width: 60px;
    position: relative;
    padding: 5px 0;
}

.topLabel {
    padding: 0 0 3px;
    color: #9c9ca2;
}

div.descriptionPane {
    max-height: 160px;
    padding: 5px 5px 5px 0;
    overflow: auto;
}

div.rowSeparator {
    padding: 0 0 8px;
    background: transparent url("/web3/build/admin/images/rowSeparator.png") repeat-x scroll bottom left;
}

a.editInventory {
    display: block;
    padding: 2px 0 0 16px;
    text-decoration: none;
    font-weight: bold;
    background: #ffffff url(/web3/build/admin/images/inventoryAdjust.png) 0% 50% no-repeat;
}

select.inventory-adjustment-type {
    width: 190px;
    display: inline-block;
}

#quantityInfo {
    float: left;
    width: 180px;
    margin: 0 40px 0 0;
}

table.inventoryCounts tr {
    font-size: 13px;
    border-bottom: 1px solid #e3e4e5;
    background: #f6f7f9;
}

table.inventoryCounts td {
    font-weight: bold;
    text-align: right;
    background-color: #f3fdb3;
}

#adjustmentHistory {
    max-height: 150px;
    overflow: scroll;
}

#adjustmentHistory table.displayGrid {
    width: 100%;
}

span.negativeNumber {
    color: #cc0000;
}

a.sort {
    display: block;
    width: 23px;
    height: 16px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/sortIcon.png) no-repeat 0 0;
    cursor: move;
}

.highlighter {
    background: #f3fdb3;
}

.highlighter.negative {
    background: #e4e6e9;
}

tr.inactiveTr,
tr.inactiveTr:hover {
    background: #e6e8e8;
}

span.runtime-alert {
    padding-left: 20px;
    background: transparent url(/web3/build/admin/images/alert.png) no-repeat 0 2px;
    color: #cc0000 !important;
}

table.configureGrid.sharing th {
    background: none;
}

tr td.theSiteName a {
    font-weight: bold;
    font-size: 12px;
    padding: 3px 0 1px 20px;
    background: transparent url(/web3/build/admin/images/sharing_off.png) no-repeat 0 0;
}

tr.shared td.theSiteName a {
    padding: 2px 0 0 20px;
    background-image: url(/web3/build/admin/images/sharing.png);
}

div.sharedFromSys {
    padding: 0 0 0 20px;
    background: transparent url(/web3/build/admin/images/sharing.png) no-repeat 0 0;
}

div.visibility {
    font-weight: bold;
    color: #cc0000;
}

div.visibility.visible {
    color: #8ac683;
}

div.visibility.visibleToSome {
    color: #ec7b00;
}

a.editProductSegments {
    float: right;
    text-decoration: none;
    color: #6c8cd1;
}

a.preview.product {
    float: right;
    padding: 8px 8px 8px 28px;
    margin: 0 0 20px;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(90, 90, 90, 0.5);
    background: #eef0f3 url(/web3/build/admin/images/view.png) no-repeat 8px 8px;
}

.settingControlBar,
.artworkOption {
    padding: 6px 3px;
    color: #7f7f7f;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #f9f9f9;
    background: #f9f9f9;
}

.settingControlBar.active,
.artworkOption.active {
    color: #5f5f5f;
    border: 1px solid #b2b3b6;
    background: transparent url(/web3/build/admin/images/grayGradient.png) repeat-x;
}

/**** SHIPPING OPTIONS ****/
.shippingServiceRow {
    padding: 0 0 5px;
    margin: 0 0 10px;
    border-bottom: 1px solid #f6f7f9;
}

#upsShippingOptionsDiv {
    padding-bottom: 5px;
}

#upsShippingOptionsDiv .shippingServiceRow,
#fedexShippingOptionsDiv .shippingServiceRow {
    padding-left: 33px;
}

a.editService {
    float: left;
    height: 16px;
    padding: 0 0 0 20px;
    margin: 0 10px 0 0;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/pencil.png) no-repeat scroll 0 50%;
}

.useShippingToggle {
    float: left;
    height: 16px;
    width: 22px;
    margin: 0 10px 0 0;
}

.shippingServiceRow.UPS {
    color: #999999;
}

.shippingServiceRow.USPS {
    color: #999999;
}

.shippingServiceRow.FEDEX {
    color: #999999;
}

.shippingServiceRow.UPS.active {
    color: #5f5f5f;
}

a.editService.cancel {
    background-image: url(/web3/build/admin/images/cancel.png);
}

a.deleteService {
    float: right;
    height: 16px;
    width: 16px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/delete.png) 0 0 no-repeat;
}

input.serviceName {
    width: 350px;
}

input.servicePrice {
    width: 50px;
}

/**** SEGMENTS ****/
.segment-list {
    padding-top: 5px;
    border-radius: 4px;
    border: 1px solid #eeeeee;
}

.segment-list-paging:before,
.segment-list-paging:after {
    display: table;
    content: " ";
}

.segment-list-paging:after {
    clear: both;
}

.segment-list.included {
    background-color: #ffffff;
}

.candidate-list-container {
    height: 550px;
    overflow: auto;
}

.segment-list p {
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 16px;
    color: #cccccc;
    text-align: center;
}

.segment-list.included p { background: transparent url(/web3/build/admin/images/users.png) no-repeat 96% 2px; }
.segment-list.included.segment-products p { background: transparent url(/web3/build/admin/images/product-stack.png) no-repeat 10px 2px; }

.segment-candidate {
    position: relative;
    height: 50px;
    border-bottom: 1px solid #efefef;
    text-align: left;
}

.segment-candidate:hover .move-candidate {
    opacity: 1;
}

.segment-list.included .segment-candidate {
    color: #22aa22;
}

.candidate-name,
.candidate-sku,
.candidate-id {
    float: left;
    width: 100%;
    padding: 5px 6px 0;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.segment-products.included .candidate-name,
.segment-products.included .candidate-sku,
.segment-products.included .candidate-id {
    text-align: right;
}

.candidate-sku,
.candidate-id {
    width: 50%;
    font-size: 11px;
    padding-bottom: 5px;
    padding-top: 0;
}

.move-candidate {
    position: absolute;
    opacity: 0;
    right: 0;
    width: 40px;
    height: 50px;
    padding: 15px 0 0;
    background: #6ec52e url(/web3/build/admin/images/move-right.png) 50% 50% no-repeat;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.move-candidate.left {
    left: 0;
    background-image: url(/web3/build/admin/images/move-left.png);
}

.move-candidate.exclude {
    background-color: #cc0000;
}

.segment-chooser {
    padding: 0 36px;
    position: relative;
    top: -55px;
}

.segment-connector {
    height: 80px;
    border: 1px solid #cccccc;
    border-bottom: none;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

.segment-controls {
    float: left;
    width: 100%;
    height: 0;
    margin-top: 10px;
    text-align: center;
}

.segment-remove-item {
    background: #ffc7c7;
    color: #c40000;
}

.filter-holder {
    margin: 0 4px 5px;
}

.filter-holder input {
    background: #ffffff url(/web3/build/admin/images/magnifier-small.png) no-repeat 2px 50%;
    text-indent: 12px;
}

.segment-action-holder {
    float: left;
    width: 100%;
    padding: 0 5px 5px 10px;
}

a.segment-action {
    float: left;
    padding: 2px 0 0 20px;
    cursor: pointer;
    background: transparent url(/web3/build/admin/images/pencil.png) 0 0 no-repeat;
}

a.segment-action.add {
    float: right;
    background-image: url(/web3/build/admin/images/add.png);
}

select.segment-selector {
    float: left;
}

.single-object-segment-controller {
    font-size: 14px;
}

.single-object-segment-controller li {
    margin-left: 0;
}

.added-segment {
    padding: 4px;
    border-bottom: 1px solid #eeeeee;
}

/**** PRICE TABLE ****/
.pointRow {
    width: 480px;
    padding: 0 0 5px;
    margin: 0 0 10px;
    border-bottom: 1px solid #f6f7f9;
}

.pointRow_full {
    width: auto;
}

a.editPoint {
    float: left;
    height: 16px;
    padding: 0 0 0 20px;
    margin: 0 10px 0 0;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/pencil.png) no-repeat scroll 0 50%;
}

a.editPoint.cancel {
    background-image: url(/web3/build/admin/images/cancel.png);
}

a.deletePoint {
    float: right;
    height: 16px;
    width: 16px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/delete.png) 0 0 no-repeat;
}

input.pointField {
    width: 35px;
    padding: 5px 6px;
    background-color: #f3fdb3;
    border: 1px solid #5f5f5f;
    border-radius: 6px;
}

/***** PICK FEES ****/
.pickRow {
    padding: 0 0 5px;
    margin: 0 0 10px;
    border-bottom: 1px solid #f6f7f9;
}

.actionArea.editPickTier {
    margin-left: 34px;
}

.editPickTier input[disabled=true],
.addPickTier input[disabled=true] {
    background: none;
}

.editPickTier select,
.addPickTier select {
    width: 80px;
}

.rangeNumberSet {
    float: left;
    width: 85px;
    background: transparent url(/web3/build/admin/images/arrowRight-gray.png) 32px 3px no-repeat;
}

.rangeNumberSet.last {
    background-image: url(/web3/build/admin/images/plus-gray.png);
}

.rangeNumber {
    float: left;
    padding: 3px 2px;
    width: 26px;
    text-align: center;
    font-weight: bold;
    background: #efefef;
}

.rangeNumber.start {
    margin: 0 20px 0 0;
}

/*** THEME PAGE ***/
.ace_editor {
    border: 1px solid #999999;
    background: #ffffff;
}

.actionArea.themeUpload ul li a {
    padding: 2px 0 2px 24px;
    background: transparent url("/web3/build/admin/images/templateIcon.png") no-repeat scroll 0 0;
}

.actionArea.themeUpload ul li a:hover {
    background-color: #f6f7f9;
}

.actionArea.themeUpload ul li a.themeUploadAction.css,
a.themeUploadAction.css:hover {
    background-image: url("/web3/build/admin/images/css.png");
}

.actionArea.themeUpload ul li a.themeUploadAction.image,
a.themeUploadAction.image:hover {
    background-image: url("/web3/build/admin/images/image.png");
}

.actionArea.themeUpload ul li a.themeUploadAction.asset,
a.themeUploadAction.asset:hover {
    background-image: url("/web3/build/admin/images/genericFileIcon.png");
}

.actionArea.themeUpload ul li a.themeUploadAction.zip,
a.themeUploadAction.zip:hover {
    background-image: url("/web3/build/admin/images/zipIcon.png");
}

.actionArea.themeUpload ul,
.actionArea.themeUpload p {
    margin-bottom: 10px;
}

.themeFolder {
    float: left;
    margin: 0 0 20px 10px;
}

.themeFolder h3 {
    margin: 0 0 10px;
    padding: 2px 2px 2px 22px;
    font: bold normal normal 13px/1.5 "Lucida Grande", Verdana, Arial, "Bitstream Vera Sans", sans-serif;
    border-bottom: 1px solid #e4e6e9;
    background: transparent url("/web3/build/admin/images/folder.png") no-repeat 0 50%;
}

.themeFolder.root h3 {
    padding-left: 0;
    background-image: none;
}

.themeFolder ul {
    margin: 0 10px;
    padding: 0;
    list-style: none;
}

.themeFolder ul li {
    float: left;
    width: 200px;
    margin: 0 20px 0 0;
}

.themeFolder ul li a {
    display: block;
    padding: 3px 0 3px 22px;
    margin: 0 0 5px;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/templateIcon.png") no-repeat 0 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.themeFolder ul li a.quickAdd,
.themeFolder.css ul li a.quickAdd,
.themeFolder.images ul li a.quickAdd,
.themeFolder.includes ul li a.quickAdd,
.themeFolder.assets ul li a.quickAdd {
    background: transparent url("/web3/build/admin/images/add.png") no-repeat 0 3px;
}

.themeFolder.css ul li a {
    background-image: url("/web3/build/admin/images/css.png");
}

.themeFolder.assets ul li a {
    background-image: url("/web3/build/admin/images/genericFileIcon.png");
}

.themeFolder.images ul li a {
    background-image: url("/web3/build/admin/images/image.png");
}

a.downloadTheme {
    display: block;
    margin: 5px 0 0;
    padding: 2px 0 0 25px;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/zipIcon.png") no-repeat 0 0;
}

a.uploadTheme {
    display: block;
    height: 44px;
    padding: 13px 8px 0 45px;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    color: #506986;
    border-radius: 8px;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    background: #ffffff url("/web3/build/admin/images/uploadArrowBig.png") no-repeat 6px 5px;
}

a.cancel.uploadTheme {
    color: #a10d25;
    background: transparent url("/web3/build/admin/images/cancel.png") no-repeat 18px 12px;
}

.editTextPanel {
    position: fixed;
    margin: 10px 0 0 20px;
    z-index: 20;
    padding: 20px 30px;
    border: 1px solid #b2b3b6;
    background: #f6f7f9;
}

.editTextPanel h1 {
    padding: 0;
    margin: 0;
    font: 23px/1.5 "Lucida Grande", Verdana, Arial, "Bitstream Vera Sans", sans-serif;
}

a.closePanel.textedit {
    float: right;
    position: relative;
    top: -10px;
    left: 20px;
    width: 22px;
    height: 22px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/closePanel.png) no-repeat 0 0;
}

.editTextPanel textarea {
    font-family: monaco, courier, courier new, fixed-width;
    font-size: 11px;
    color: #0000dd;
    padding: 10px;
}

.panelActions {
    padding: 10px 0 0;
}

a.trashIt {
    float: right;
    padding: 3px 0 3px 22px;
    margin: 5px 5px 0 0;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/trashIcon.png) no-repeat 0 50%;
}

a.trashIt.left {
    float: left;
}

div.themeImageContainer,
div.themeFileContainer {
    padding: 10px;
    width: 550px;
    height: 400px;
    overflow: auto;
    background: #ffffff;
}

div.themeFileContainer {
    height: 200px;
}

a.downloadFile {
    display: inline;
    margin-left: 5px;
    padding: 5px 0 5px 15px;
    font-weight: bold;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/download.png") no-repeat scroll -2px 4px;
}

tr.userStatusIsRetired {
    font-style: italic;
    background: #f4f4f4;
}

/**** USER ADDRESSES ****/
div.addAddress {
    margin: 0 0 10px;
}

div.name {
    padding-left: 18px;
    background: transparent url(/web3/build/admin/images/user.png) no-repeat 0 0;
}

div.userAddress {
    float: left;
    width: 205px;
    padding: 10px;
    margin: 0 10px 10px 0;
    border-radius: 8px;
    border-bottom: 1px solid #dfe1e4;
    background: #ffffff;
}

div.userAddressContainer {
    height: 125px;
}

div.userAddress a.editObject {
    float: right;
    height: 20px;
}

div.addressType {
    text-align: right;
    font-style: italic;
}

ul.radioCheckList {
    list-style: none;
}

.radioOptionGroup {
    float: left;
    width: 330px;
    margin: 0 10px 20px 0;
}

.radioHolder {
    padding: 5px 0 0;
    border: 1px solid #dfe1e4;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: transparent url("/web3/build/admin/images/lightGradient.png") repeat-x scroll bottom left;
}

.radioHolder.selected {
    border: 1px solid #dfe1e4;
    border-bottom: none;
    background: transparent url("/web3/build/admin/images/lightGradient.png") repeat-x scroll bottom left;
}

a.radioGroupToggler {
    display: block;
    padding: 0 0 5px 25px;
    font-weight: bold;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/radios.png") no-repeat scroll 5px 2px;
}

a.radioGroupToggler.on {
    color: #5f5f5f;
    background-position: 5px -28px;
}

.radioOptions {
    padding: 10px;
    min-height: 195px;
    border: 1px solid #dfe1e4;
    border-top: none;
    border-radius: 0 0 4px 4px;
}

.radioOptions.selected {
    border: 1px solid transparent;
    border-top: none;
    background-color: #ffffff;
}

.presetDimensions {
    width: 300px;
    height: 270px;
    overflow: auto;
    padding: 5px;
    margin: 5px 0 10px;
    border: 1px solid #dfe1e4;
}

table.presets td {
    padding: 2px 0 5px;
    border-bottom: 1px solid #dfe1e4;
}

.presets td a.remove {
    padding: 0 0 0 20px;
    float: left;
}

.presetItem {
    padding: 2px 0;
    border-bottom: 1px solid #f6f7f9;
}

.theDimensions {
    float: left;
    width: 110px;
    color: #000000;
}

.dimensionOverride {
    padding: 10px 0 0;
}

.contentBlockPreview {
    max-height: 60px;
    padding: 5px;
    overflow: auto;
    background: #ffffff;
}

/*** MATERIAL EDIT ***/
a.materialEdit {
    text-decoration: none;
}

a.materialEdit:hover {
    text-decoration: underline;
}

/**** DEVICE GRID ****/
h3.gridHeader {
    padding: 0 0 0 45px;
    height: 32px;
}

h3.gridHeader.ink {
    background: transparent url("/web3/build/admin/images/inkIcon.png") no-repeat scroll 0 0;
}

h3.gridHeader.device {
    background: transparent url("/web3/build/admin/images/deviceIcon.png") no-repeat scroll 0 0;
}

h3.gridHeader.pressSheet {
    background: transparent url("/web3/build/admin/images/pressSheetIcon.png") no-repeat scroll 0 0;
}

a.checkbox {
    display: block;
    height: 20px;
    padding: 0 0 5px 25px;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/checkboxes.png") no-repeat scroll 0 0;
}

a.checkbox.on {
    background-position: 0 -29px;
}

a.checkbox.small {
    height: 16px;
    padding: 0 0 4px 22px;
    background: transparent url("/web3/build/admin/images/checkboxesSmall.png") no-repeat scroll 0 1px;
}

a.checkbox.small.on {
    background-position: 0 -21px;
}

a.checkbox.small.preferences,
a.checkbox.small.preferences {
    margin: 0 0 5px 20px;
}

a.checkbox.share {
    float: left;
    height: 40px;
    padding: 0 0 5px 45px;
    font-size: 18px;
    font-weight: bold;
    line-height: 2em;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/checkboxesBig.png") no-repeat scroll 0 0;
}

a.checkbox.share.on {
    background-position: 0 -55px;
}

a.checkbox.overrides {
    float: right;
    height: 20px;
    margin: 1px 0 0;
    padding: 0 23px 5px 0;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/checkboxes.png") no-repeat scroll 100% 0;
}

a.checkbox.overrides.on {
    background-position: 100% -29px;
}

.gridConfigureArea {
    padding: 20px;
    margin: 0 0 20px;
    background-color: #ffffff;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    border-radius: 4px;
}

.gridConfigureArea.withTip {
    border-bottom: 0;
    margin-bottom: 0;
    border-radius: 4px 4px 0 0;
}

table.displayGrid {
    font-size: 11px;
}

table.displayGrid th {
    padding: 0 10px 0 0;
    font-weight: normal;
    color: #b5b5b5;
}

table.displayGrid td {
    padding: 10px 10px 6px 0;
    background: transparent url("/web3/build/admin/images/rowSeparator.png") repeat-x scroll bottom left;
}

table.configureGrid {
    margin: 0;
}

table.configureGrid.bump10 {
    margin-bottom: 10px;
}

table.configureGrid th {
    padding: 0 5px 0 0;
    font-weight: normal;
    color: #b5b5b5;
}

table.configureGrid td {
    padding: 10px 5px 6px 0;
    vertical-align: middle;
    background: transparent url("/web3/build/admin/images/rowSeparator.png") repeat-x scroll bottom left;
}

table.configureGrid.storedArtwork {
    margin-bottom: 20px;
}

table.configureGrid.storedArtwork th {
    background-color: transparent;
}

table.configureGrid.included td {
    background-color: #f6f7f9;
}

table.configureGrid.excluded td {
    color: #9c9ca2;
}

table.configureGrid .objectName {
    width: 160px;
    padding-right: 5px;
}

table.configureGrid .classification {
    width: 180px;
    padding-right: 5px;
}

table.configureGrid .optionCheck {
    width: 80px;
}

table.configureGrid .sortHandle,
table.configureGrid .includeRow {
    width: 40px;
    padding-left: 10px;
}

table.configureGrid .editLink {
    width: 33px;
    padding-left: 10px;
}

table.configureGrid .excludeLink {
    width: 33px;
    padding-left: 10px;
}

table.configureGrid th.sortHandle {
    width: 45px;
    padding-left: 5px;
}

table.configureGrid th.classification {

}

td.control,
th.control {
    width: 42px;
}

/* update edit is width of two control cols + padding */
td.updateEdit {
    width: 89px;
}

td.control a {
    display: block;
    width: 23px;
    height: 16px;
    padding: 0;
    text-decoration: none;
    background: transparent url("") no-repeat 0 0;
}

td.control.edit a {
    background-image: url(/web3/build/admin/images/pencil.png);
}

td.control.sort a {
    background-image: url(/web3/build/admin/images/sortIcon.png);
    cursor: move;
}

td.control.remove a {
    background-image: url(/web3/build/admin/images/delete.png);
}

td.control.default a {
    background: transparent url(/web3/build/admin/images/radios.png) no-repeat 0 2px;
}

td.control.default a.on {
    background-position: 0 -28px;
}

td.control.cancelEdit a {
    background-image: url(/web3/build/admin/images/cancel.png);
}

table.configureGrid tr.editRow,
table.configureGrid tr.addRow {
    background: #f6f7f9;
}

table.configureGrid tr.editRow td,
table.configureGrid tr.addRow td {
    padding-top: 2px;
    padding-bottom: 4px;
}

.subView {
    margin: 5px 0 0 20px;
}

#proofTip,
#turnTip {
    font-size: 12px;
}

.chosenProductionOptions {
    float: left;
    width: 635px;
}

.availableProductionOptions {
    float: left;
    width: 322px;
}

div.excludedList {
    float: left;
    padding: 10px;
    margin: 0 0 20px;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    border-radius: 4px;
    background: #f6f7f9;
}

.excludedList p {
    margin-bottom: 10px;
}

.excludedList ul li {
    float: left;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 3px 0;
    border-bottom: 1px solid #fefefe;
}

.excludedList ul li a.quickAdd {
    text-decoration: none;
    float: left;
    background-position: 0 5px;
}

a.dragHandle {
    display: block;
    height: 20px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/draggerHandle.png) no-repeat scroll bottom left;
    cursor: move;
}

a.editObject {
    padding: 5px 0 5px 20px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/pencil.png) no-repeat scroll 0 50%;
}

.addNewDeviceProperty {
    padding: 10px 0 2px;
}

a.selectionToggle {
    color: #b9d0eb;
    text-decoration: none;
}

.customerPreview {
    float: right;
    width: 180px;
    padding: 10px;
    position: relative;
    top: -20px;
    left: 20px;
    border-radius: 4px;
    background-color: #f6f7f9;
}

.customerPreview label {
    display: block;
}

.customerPreview select {
    width: 160px;
    margin-bottom: 8px;
    color: #5f5f5f;
    border-color: #b5b5b5;
    background-color: #f6f7f9;
}

/**** LARGE FORMAT ****/
.sandwichGroup {
    padding: 20px 20px 20px 40px;
    border: 1px solid #b5b5b5;
}

.sandwichGroup.a {
    background: transparent url("/web3/build/admin/images/lfSide1Bg.png") no-repeat scroll left center;
}

.sandwichGroup.b {
    background: transparent url("/web3/build/admin/images/lfSide2Bg.png") no-repeat scroll left center;
}

.lfComponent {
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    border-radius: 4px;
}

.a .lam,
.b .adhesiveWithMount {
    padding-bottom: 23px;
    background: transparent url("/web3/build/admin/images/lfArrowDownBg.png") no-repeat scroll center bottom;
}

.a .adhesive,
.b .lam,
.b .adhesive {
    padding-top: 23px;
    background: transparent url("/web3/build/admin/images/lfArrowUpBg.png") no-repeat scroll center top;
}

.a .adhesiveWithMount {
    padding-bottom: 23px;
    background: transparent url("/web3/build/admin/images/lfArrowDownBg.png") no-repeat scroll center bottom;
}

/**** OPERATIONS ****/
.operationItemCostValues {
    margin: 5px 0 5px 20px;
    padding: 8px;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    border-radius: 12px;
    border-top-left-radius: 0;
    background-color: #f6f7f9;
}

.radio.block {
    float: none;
    display: block;
    width: auto;
    margin-bottom: 5px;
    font-weight: bold;
}

/*  */
.accountFieldHolder {
    float: left;
    width: 240px;
    padding: 0 10px 10px 0;
}

.accountFieldHolder label {
    display: block;
    font-weight: bold;
    padding-bottom: 2px;
}

table.adminFields tr td input.laName {
    width: 140px;
}

table.adminFields tr td input.laCode {
    width: 75px;
}

/**** SHIPPING OPTIONS ****/
.activeBar {
    padding: 7px 0 7px 20px;
    font: italic normal normal 18px/18px Georgia, "Times New Roman", "Bitstream Charter", Times, serif;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    border-radius: 4px;
    background: transparent url(/web3/build/admin/images/lightGradient.png) repeat-x scroll 0 0;
}

.activeBar.open {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

a.accordianToggle {
    display: block;
    padding: 0 0 0 25px;
    text-decoration: none;
    background: transparent no-repeat scroll 0 50%;
}

.closed a.accordianToggle {
    background-image: url(/web3/build/admin/images/expand.png);
}

.open a.accordianToggle {
    background-image: url(/web3/build/admin/images/contract.png);
}

table.configureGrid .short {
    width: 75px;
    padding-right: 5px;
}

table.configureGrid .medium {
    width: 120px;
    padding-right: 5px;
}

table.configureGrid .long {
    width: 200px;
    padding-right: 5px;
}

table.configureGrid tr:hover {
    background: #f6f7f9;
}

.bumpDown20 { margin-top: 20px; }
.bumpDown10 { margin-top: 10px; }
.bottom40 { margin-bottom: 40px; }
.bottom20 { margin-bottom: 20px; }
.bottom10 { margin-bottom: 10px; }

.indent {
    margin-left: 20px;
}

.hideIt {
    display: none;
}

/**** MULTICHOOSER ****/
table.multiChooserIncluded {
    margin-bottom: 0;
}

table.multiChooser td,
table.multiChooserIncluded td,
table.multiChooserIncluded th {
    padding: 0 2px;
    text-align: left;
    font-weight: normal;
}

table.multiChooserIncluded th {
    font-size: 10px;
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #b2b3b6;
    background-color: #f6f7f9;
}

table td.multiChooserButtons {
    vertical-align: middle;
    padding: 0 8px;
}

table td.multiChooserButtons a {
    display: block;
    padding: 5px 5px 3px;
    margin-bottom: 10px;
    border: 1px solid #b2b3b6;
    border-radius: 6px;
    background: transparent url("/web3/build/admin/images/grayGradient.png") repeat-x scroll 0 0;
}

table.multiChooser a {
    text-decoration: none;
}

table.multiChooser td label {}

div.multiSelect {
    font-size: 11px;
    padding: 0;
    margin: 0 0 2px;
    background-color: #ffffff;
    width: 300px;
    height: 200px;
    line-height: normal;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    border-radius: 4px;
}

div.multiSelect.withChecks {
    width: 320px;
    overflow: auto;
}

div.multiSelect a.off,
div.multiSelect a.on {
    display: block;
    padding: 2px;
    margin: 2px 0 0;
}

div.multiSelect a.off {
    background-color: #ffffff;
}

div.multiSelect a.on {
    background-color: #314a66;
    color: #ffffff;
}

a.checkOff,
a.checkOn {
    display: block;
    background-repeat: no-repeat;
    width: 14px;
    padding: 1px 0;
}

a.checkOff {
    background-image: url(/web3/build/admin/images/checkOff.gif);
}

a.checkOn {
    background-image: url(/web3/build/admin/images/checkOn.gif);
}

.defaultCheck,
.editableValue {
    width: 40px;
    background-color: #f6f7f9;
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
}

.editableValue a {
    float: left;
    padding: 2px 0 0 20px;
    background: transparent url(/web3/build/admin/images/pencil.png) no-repeat scroll bottom left;
}

div.multiChooserEditPanel {
    position: absolute;
    top: -500px;
    left: 0;
    margin-left: 20px;
    padding: 10px;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    border-radius: 4px;
    background-color: #ffffff;
}

div.multiChooserEditPanel input {
    width: 50px;
}

/**** META FIELDS ****/
.editObject.metaField {
    display: block;
    margin: 0 0 0 10px;
    padding: 5px 0 5px 20px;
    background-position: 0 50%;
}

.remove.metaField {
    display: block;
    padding: 5px 0 5px 20px;
    margin: 0 0 0 10px;
}

.addDDItems {
    padding: 10px;
    background: #f6f7f9;
    border: 1px solid #dfe1e4;
    border-top: none;
    border-bottom: 1px solid #b2b3b6;
    border-radius: 12px;
    border-top-left-radius: 0;
}

.addDDItems table.adminFields td {
    background: none;
}

.addDDItems table.adminFields td input {
    width: 200px;
    border: 1px solid #b2b3b6;
}

/***** PRODUCT META *****/
.questionField {
    margin: 0 0 10px;
}

.metaQuestion {
    display: block;
    font-size: 12px;
    margin: 0 0 2px;
}

/**** TAB OPTIONS ****/
.tabOption {
    float: left;
    padding: 5px 8px;
    margin: 0 2px 0 0;
    border: 1px solid #ffffff;
}

.tabOn {
    background: #ffffff;
}

/**** DRAG SORTER ****/
.applySortChanges {
    padding: 10px 0;
    text-align: center;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    box-shadow: inset 0 -2px 2px rgba(255, 255, 255, 0.8), inset 0 1px 2px rgba(90, 90, 90, 0.5);
    background: transparent url(/web3/build/admin/images/orderItemDetailBg.png) 0 0;
}

.placeholder {
    background-color: #e4e6e9;
}

.ui-nestedSortable-error {
    background: #fbe3e4;
    color: #8a1f11;
}

.sortContainer ol {
    margin: 0;
    padding: 0;
    padding-left: 30px;
}

ol.sortable,
ol.sortable ol {
    margin: 0 0 0 25px;
    padding: 0;
    list-style-type: none;
    border-left: 1px dotted #cccccc;
}

ol.sortable {
    margin: 0;
    border-bottom: 1px dotted #cccccc;
}

.sortable li {
    margin: 0;
    padding: 0;
}

.sortable li div {
    border: 1px solid #e4e6e9;
    border-top: 1px solid #ffffff;
    background-color: #ffffff;
    margin: 0 0 2px;
    cursor: move;
    border-radius: 6px;
}

.sortContainer {
    margin-bottom: 20px;
}

.pageSorter1,
.pageSorter2 {
    z-index: 1;
    position: relative;
    padding: 5px;
    margin: 2px 0;
    background-color: #f6f7f9;
    border: 1px solid #e4e6e9;
    border-top: 1px solid #ffffff;
    clear: both;
}

.pageSorter2 {
    margin-left: 40px;
}

a.remove.menuItem,
a.editObject.menuItem,
a.viewHistory.menuItem {
    float: right;
}

a.editObject.menuItem {
    float: right;
}

.sortable li div div.menuItemDescription {
    float: right;
    margin: 6px 10px 0 0;
    font-size: 10px;
    color: #999999;
    border: 0;
}

.sortable li div div.catProductCount,
a.catProductCount {
    float: right;
    margin: 6px 10px 0 0;
    font-size: 10px;
    border: 0;
}

.catProductCount {
    float: right;
    margin: 6px 10px 0 0;
    font-size: 10px;
    border: 0;
}

.menuItemDetails {
    margin: 5px 0 0 18px;
    max-width: 450px;
}

span.menuItemName,
span.categoryName {
    display: block;
    padding: 6px 10px;
}

span.categoryName {
    padding-left: 22px;
    background: transparent url(/web3/build/admin/images/folder.png) 3px 5px no-repeat;
}

span.menuItemName.hidden {
    color: #999999;
    font-style: italic;
}

span.divisionName.menuItem,
span.auditTermsOrders.menuItem,
span.divisionTaxExempt.menuItem {
    display: inline-block;
}

a.radio.offering,
a.radio.page,
a.radio.heading,
a.radio.external {
    float: none;
    display: block;
    width: auto;
}

a.radio {
    float: left;
    width: 130px;
    padding-left: 20px;
    text-decoration: none;
    background: transparent url("/web3/build/admin/images/radios.png") no-repeat scroll 0 2px;
    margin: 0;
}

a.radio.on {
    background-position: 0 -28px;
}

a.radioFancy {
    display: block;
    width: 258px;
    padding: 5px 5px 5px 25px;
    margin-bottom: 4px;
    font-weight: bold;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 6px;
    background: transparent url("/web3/build/admin/images/radios.png") no-repeat scroll 5px 6px;
}

a.radioFancy.on {
    color: #5f5f5f;
    border: 1px solid #dfe1e4;
    background-color: #ffffff;
    background-position: 5px -24px;
}

/**** GENERAL FORM ELEMENTS ****/
input,
select,
textarea,
.pill-filter {
    padding: 8px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    width: 100%;
    font-size: 12px;
    font-family: "Proxima Nova Soft W03 Reg", Helvetica, Arial;
}

input[type=checkbox],
input[type=radio] {
    width: auto;
}

.longField {
    width: 200px;
}

input.short {
    width: 70px;
}

select {
    display: block;
    height: 32px;
    padding: 6px 12px;
    line-height: 1.428571429;
    vertical-align: middle;
}

select option.disabled {
    color: #7f7f7f;
}

input[disabled=true] {
    border-color: #cccccc;
    background: #eeeeee;
}

textarea {
    line-height: 1.5;
}

textarea.big {
    height: 300px;
}

label.inline {
    padding: 0 5px 0 2px;
}

label.point {
    cursor: pointer;
}

.notesField {
    padding: 0;
    border: none;
    color: #5f5f5f;
    height: 100px;
}

.notesField.big {
    height: 200px;
    width: 300px;
}

table.adminFields tr td input.notesField {
    height: auto;
    width: 350px;
}

table.radioList {
    margin: 0;
}

table.radioList td {
    padding: 4px 4px 2px 0;
    border: none;
    background: none;
}

/* .inputNumberPlain - removes the spinner from input[type="number"] */
.inputNumberPlain {
    -moz-appearance: textfield;
}

.inputNumberPlain::-webkit-inner-spin-button,
.inputNumberPlain::-webkit-outer-spin-button {
    display: none;
}

/**** BUTTONS ****/
button {}

button.signin {
    padding: 5px 20px;
    font-weight: bold;
    font-size: 11px;
    text-decoration: none;
    color: #222222;
    border-radius: 6px;
    border: 1px solid #b2b3b6;
    background: #eafbd7;
}

a.button {
    float: left;
    padding: 5px 17px 5px 30px;
    font-weight: bold;
    font-size: 11px;
    text-decoration: none;
    color: #222222;
    border: 1px solid #b2b3b6;
    border-radius: 6px;
}

.panelButtons a.button {
    float: right;
    margin: 0 0 0 15px;
}

a.button.login {
    padding-left: 20px;
    padding-right: 20px;
    background: #eafbd7;
}

a.button.save {
    background: #eafbd7 url(/web3/build/admin/images/save.png) no-repeat 10px 50%;
}

a.button.cancel {
    background: #eeeeee url(/web3/build/admin/images/cancel.png) no-repeat 10px 50%;
}

a.button.edit {
    border-color: #222222;
    background: #fef39d url(/web3/build/admin/images/pencil.png) no-repeat 10px 50%;
}

a.button.done {
    padding-left: 20px;
    padding-right: 20px;
    background: #eeeeee;
}

.panelActions a.button {
    margin-right: 15px;
}

a.button.secondary.save {
    background: url(/web3/build/admin/images/save.png) no-repeat 10px 50%, url(/web3/build/admin/images/grayGradient.png) repeat-x 0 0;
}

a.button.secondary.add {
    background: url(/web3/build/admin/images/add.png) no-repeat 10px 50%, url(/web3/build/admin/images/grayGradient.png) repeat-x 0 0;
}

a.button.secondary.fileUpload {
    background: url(/web3/build/admin/images/fileUpload.png) no-repeat 10px 50%, url(/web3/build/admin/images/grayGradient.png) repeat-x 0 0;
}

button.save,
button.delete,
button.add,
button.cancel {
    padding: 8px 8px 8px 25px;
    margin-right: 15px;
    text-transform: uppercase;
    font-size: 13px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background: #eafbd7 none 5px 50% no-repeat;
    cursor: pointer;
    font-family: "Proxima Nova Soft W03 Reg", Helvetica, Arial;
}

button.save {
    background-image: url(/web3/build/admin/images/save.png);
}

button.save-in-progress {
    background-image: url(/web3/build/admin/images/spinner_small.gif);
}

button.save.shipIt {
    padding-left: 26px;
    padding-right: 10px;
    background-image: url(/web3/build/admin/images/truckShipIt.png);
}

button.save.shipIt.late {
    background-color: #f14d4d;
    color: #ffffff;
}

button.delete {
    background-image: url(/web3/build/admin/images/delete.png);
    background-color: #eeeeee;
}

button.add {
    background-image: url(/web3/build/admin/images/add.png);
}

button.cancel {
    background-image: url(/web3/build/admin/images/cancel.png);
    background-color: #eeeeee;
}

button.prev-next {
    padding: 5px 5px 7px;
    color: #506986;
    border: 1px solid #b2b3b6;
    border-radius: 6px;
    background: transparent url("/web3/build/admin/images/grayGradient.png") repeat-x scroll 0 0;
    cursor: pointer;
}

button.secondary {
    color: #506986;
    border: 1px solid #b2b3b6;
    border-radius: 4px;
    background: #eeeeee;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    height: 32px;
}

button.secondary.inline {
    margin-left: 10px;
}

button.secondary.spaceRight {
    margin-right: 5px;
}

/**** CHART ****/
.chart {
    margin-bottom: 20px;
}

/**** USER MESSAGES ****/
.userMessages {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

.umBg {
    width: 100%;
    height: 80px;
    background: #000000 no-repeat 5% 50%;
}

.umWrapper {
    width: 600px;
    margin: auto;
}

.umIcon {
    float: left;
    width: 0;
    height: 75px;
    background: transparent none no-repeat 0 50%;
}

.success .umBg { background-image: url(/web3/build/admin/images/alertSuccess_big.png); }
.error .umBg { background-image: url(/web3/build/admin/images/alertError_big.png); }
.info .umBg { background-image: url(/web3/build/admin/images/alertWarning_big.png); }

.um {
    position: relative;
    float: left;
    width: 460px;
    padding: 30px 0 0 10px;
    color: #ffffff;
    font-weight: bold;
    font-size: 13px;
}

#userMessageAlert,
#userMessageError,
#userMessageInfo {
    padding: 8px 0 8px 40px;
    margin: 5px 0 0;
    font-weight: bold;
    background: transparent none no-repeat scroll 0 0;
}

#userMessageError { background-image: url(/web3/build/admin/images/alertError.png); }
#userMessageAlert { background-image: url(/web3/build/admin/images/alertWarning.png); }
#userMessageInfo { background-image: url(/web3/build/admin/images/alertSuccess.png); }

#userMessageAlert strong,
#userMessageError strong,
#userMessageInfo strong {
    display: block;
    padding-bottom: 8px;
    border-bottom: 1px solid #e4e6e9;
}

div.cometMessages {
    display: none;
    clear: both;
}

/**** PANELS ****/
.panel,
.panelAlert {
    top: -1000px;
    position: absolute;
    z-index: 25;
    padding: 5px;
    border: 1px solid #444444;
    border-top: 1px solid #acacac;
    border-bottom-color: #222222;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.7);
}

.panel .shippingLogo {
    float: right;
}

.closeTipPanel {
    float: right;
}

.closeQuoteTipPanel {
    height: 22px;
    padding: 10px;
}

.quoteTipContent {
    width: 600px;
    padding: 10px;
}

a.closePanel.tipView {
    float: right;
    width: 22px;
    height: 22px;
    text-decoration: none;
    background: transparent url(/web3/build/admin/images/closePanel.png) no-repeat 0 0;
}

.panelContent {
    padding: 15px;
}

.panelContent pre {
    white-space: pre-wrap;
}

.panelButtons {
    height: 45px;
    padding: 0 15px;
    text-align: right;
}

.buttonHolder {
    height: 30px;
    padding: 5px 0 0 120px;
}

.panel.alert,
.panel.confirm {
    z-index: 35;
    width: 350px;
}

.panel.alert h2 {
    color: #a10d25;
}

.siteChooserBox {
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
}

ul.siteChooserMenu {
    float: left;
    margin-bottom: 40px;
}

ul.siteChooserMenu li {
    float: left;
    width: 470px;
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.siteChooserMenu li a.siteName {
    float: left;
    width: 300px;
    padding: 5px;
    margin-right: 10px;
    font-weight: bold;
    text-decoration: none;
}

ul.siteChooserMenu li a.keepSiteOpen {
    float: left;
    width: 120px;
    margin-top: 5px;
    font-size: 11px;
    text-decoration: none;
}

ul.siteChooserMenu li:hover {
    background-color: #f6f7f9;
}

.indicatorBullet {
    width: 15px;
    height: 15px;
    margin-top: 4px;
}

.siteBullet {
    width: 15px;
    height: 15px;
    margin: auto;
    padding-right: 5px;
    display: inline-block;
    border: 1px solid gray;
    vertical-align: middle;
    border-radius: 2px;
}

/**** TEST CALC PANEL ****/
#chartDiv {
    margin-top: 20px;
}

table.adminFields.calcTesting td:first-child {
    width: 160px;
}

/**** SHIPPING INFO ****/
table.shippingInfo td {
    padding: 2px 5px 2px 0;
}

table.trackingLog {
    border: 1px solid #efefef;
}

table.shippingUspsInfo td:nth-child(1) {
    padding: 2px 5px 2px 0;
    width: 25%;
}

table.shippingUspsInfo td:nth-child(2) {
    padding: 2px 5px 2px 0;
    width: 100%;
}

table.trackingLog {
    border: 1px solid #efefef;
}

table.trackingLog td {
    font-size: 11px;
    border-bottom: 1px solid #efefef;
}

.float-indicator {
    float: left;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 7px;
    margin: 4px 4px 0 0;
}

.level-1 { background: #ff4c4c; }
.level-2 { background: orange; }
.level-3 { background: #ffff32; }
.level-4 { background: #54b5ff; }
.level-5 { background: #ff4c4c; }

.ship-indicator {
    float: left;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 7px;
    margin: 4px 4px 0 0;
}

.single-job {
    background: #ffd485;
}

.multi-job {
    background: #b5e4ff;
}

/**** FOOTER ****/
#footer {
    float: left;
    clear: both;
    padding: 60px 0 20px;
    width: 100%;
}

.viewSiteNotes {
    float: left;
    padding: 0 0 0 20px;
    text-decoration: none;
    background: url(/web3/build/admin/images/note.png) no-repeat 0 0;
}

.copyright {
    float: left;
    margin-right: 40px;
    letter-spacing: -0.01em;
}

/**** COLOR PICKER ****/
#colorSelector {
    background: url("/web3/build/admin/images/colorSelect.png") repeat scroll 0 0 transparent;
    height: 36px;
    position: relative;
    width: 36px;
}

#colorSelector div {
    background: url("/web3/build/admin/images/colorSelect.png") repeat scroll center center transparent;
    height: 30px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 30px;
}

/*****  admin order detail plupload *****/
.uploadDropTarget {
    position: relative;
    margin-top: 10px;
    padding: 8px 5px 8px 25px;
    background: transparent url(/web3/build/admin/images/add.png) no-repeat 5px 50%;
    color: #999999;
    cursor: pointer;
    clear: both;
}

.uploadDropTarget.dragover {
    background-image: url(/web3/build/admin/images/save.png);
    background-color: #e3f6dc;
    transition: background-color 200ms ease-in 100ms;
}

.uploadDropTarget:hover {
    color: #71ab47;
    background-color: #f6f7f9;
}

.fileProgressContainer,
.fileProgress {
    position: relative;
    clear: both;
}

.uploadProgressBarBackground {
    float: left;
    height: 15px;
    width: 85%;
    margin-right: 10px;
    border-right: 1px solid #e3e7e6;
    border-left: 1px solid #e3e7e6;
    background: #f0f4f3 url(/web3/build/admin/images/progressBar.gif) repeat-x 0 0;
}

.uploadProgressBar {
    height: 15px;
    padding: 1px 0;
    background: transparent url(/web3/build/admin/images/progressBar.gif) repeat-x 100% -31px;
}

/***** TRE *****/
.listSection {
    width: 100%;
    min-height: 220px;
    padding: 20px 10px 0;
    box-sizing: border-box;
    float: left;
    border-top: 1px solid #ffffff;
    background: transparent url(/web3/build/base/images/listSectionBg.png) top left repeat-x;
}

table.tre tr {
    cursor: pointer;
}

table.tre tbody tr:hover {
    color: #5cb85c;
    background: #ffffff;
}

table.tre tbody tr.noAction:hover {
    color: inherit;
    background: inherit;
}

table.tre tbody tr.noAction td {
    cursor: auto;
}

table.tre td,
.tre th {
    font-size: 12px;
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
    border-top: 1px solid #dddddd;
}

table.tre th {
    border-top: 0;
    border-bottom: 2px solid #dddddd;
    white-space: nowrap;
}

.ellipsis {
    white-space: nowrap;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nowrap {
    white-space: nowrap;
}

.scroll-overflow {
    overflow: auto;
}

.pager {
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.pager a {
    display: inline-block;
    padding: 4px 9px;
    margin: 0 1px;
    text-decoration: none;
    border-radius: 4px;
}

.pager a:hover {
    background-color: #fafafa;
}

.pager a.thisPage {
    background-color: #ffffff;
}

.pagerStats {
    padding: 5px 0;
    font-size: 12px;
    font-weight: bold;
}

.pagerControl {
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
    text-align: right;
}

table.c-key-value-display {
    width: 100%;
    max-width: 500px;
    margin: 5px 0;
    border: 0;
    border-collapse: collapse;
}

table.c-key-value-display.disabled {
    color: #aaaaaa;
}

table.c-key-value-display.strict-50 {
    table-layout: fixed;
}

table.c-key-value-display.strict-50 th {
    width: 50%;
}

table.c-key-value-display.bordered tr {
    border-bottom: 1px solid #eeeeee;
}

table.c-key-value-display th {
    width: 150px;
    padding: 6px 5px 6px 0;
    text-align: left;
    vertical-align: top;
}

table.c-key-value-display td {
    padding: 6px 5px;
    vertical-align: top;
}

/* paging */
.c-search-counts,
.c-paging {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.c-paging-control-group {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.c-paging-control-group a.c-paging-control {
    display: inline-block;
    position: relative;
    float: left;
    padding: 5px 10px;
    margin-bottom: 0;
    margin-left: -1px;
    line-height: 1.4;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background: #ffffff;
    border: 1px solid #cccccc;
    text-decoration: none;
}

.c-paging-control-group > a.c-paging-control:first-child {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.c-paging-control-group > a.c-paging-control:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.c-paging-control-group a.c-paging-control:hover,
.c-paging-control-group a.c-paging-control.current-page {
    background-color: #efefef;
}

.c-paging-control-group a.c-paging-control.end-of-list {
    color: #eeeeee;
}

.c-paging-control-group a.c-paging-control.end-of-list:hover {
    background-color: #ffffff;
}

/*  */
.spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 0;
    margin: 0;
    background: transparent url("/web3/build/admin/images/spinner_small.gif") no-repeat 0 0;
}

/* Angular animations **/
.animate-all {
    transition: all linear 0.5s;
}

.animate-all.highlight-yellow {
    background-color: #ffffcc;
}

.helpdesk {
    float: right;
    padding-top: 5px;
    margin: 0 0 0 20px;
    position: relative;
}

.helpdesk-link {
    text-decoration: none;
}

.helpdesk-link i {
    font-size: 20px;
    position: relative;
    top: 2px;
    margin-right: 2px;
}

@-moz-keyframes fadein {
    from {
        opacity: 0;
        top: 25px;
    }

    to {
        opacity: 1;
        top: 45px;
    }
}

@-webkit-keyframes fadein {
    from {
        opacity: 0;
        top: 25px;
    }

    to {
        opacity: 1;
        top: 45px;
    }
}

@-ms-keyframes fadein {
    from {
        opacity: 0;
        top: 25px;
    }

    to {
        opacity: 1;
        top: 45px;
    }
}

@-o-keyframes fadein {
    from {
        opacity: 0;
        top: 25px;
    }

    to {
        opacity: 1;
        top: 45px;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
        top: 25px;
    }

    to {
        opacity: 1;
        top: 45px;
    }
}

.helpdesk-intro {
    display: none;
    position: absolute;
    top: 45px;
    right: -10px;
    width: 455px;
    background: white;
    color: #8f8f8f;
    z-index: 23;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 15px 20px 10px;
    -webkit-animation: fadein 1.1s;
    -moz-animation: fadein 1.1s;
    -ms-animation: fadein 1.1s;
    -o-animation: fadein 1.1s;
    animation: fadein 1.1s;
}

.helpdesk-intro.isShown {
    display: block;
}

.helpdesk-intro:before {
    content: " ";
    position: absolute;
    top: -10px;
    right: 56px;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 20px solid #ffffff;
}

.helpdesk-intro-dismiss {
    position: absolute;
    top: 10px;
    right: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    background: transparent;
    color: #888888;
    transition: color ease-in-out 0.2s;
}

.helpdesk-intro-dismiss:focus {
    outline: 0;
}

.helpdesk-intro-dismiss:hover,
.helpdesk-intro-dismiss:focus {
    color: #cccccc;
}

.helpdesk-intro.ng-leave {
    transition: 0.15s linear all;
    opacity: 1;
}

.helpdesk-intro.ng-leave.ng-leave-active {
    opacity: 0;
    right: -30px;
}

.listingTable {
    table-layout: fixed;
}

/* ActionList */
.actionList:after,
.actionList:before {
    content: " ";
    display: table;
}

.actionList:after {
    clear: both;
}

.actionList {
    /* clean up default list styles so I can remove this */
    list-style: none;
    margin: 0;
    padding: 0;
}

.actionList > * {
    float: left;

    /* clean up default list styles so I can remove this */
    padding: 0;
    margin: 0;
}

.actionList > * + * {
    margin: 0 0 0 10px;
}

.actionList_right > * {
    float: right;
}

.actionList_right > * + * {
    margin: 0 10px 0 0;
}

.actionList button {
    margin: 0;
}

.actionsCell {
    white-space: nowrap;
    text-align: right;
}

.actionsCell button + button {
    margin: 0 0 0 5px;
}

.actionsCell_left {
    text-align: left;
}

/* Temp until make better object */
.createdProducts {
    margin: 20px 0;
}

.createdProducts h3 {
    margin-bottom: 5px;
}

.createdList {
    list-style: none;
}

.btnAsLink {
    background: transparent;
    border: 0;
    padding: 0;
    height: auto;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    margin: 0;
    text-align: center;
    text-decoration: none;
}

.btnAsLink:hover {
    text-decoration: underline;
}

.btnIcon {
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 0;
    border: 0;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: transparent;
    vertical-align: middle;
    text-decoration: none;
    text-indent: -9999px;
    cursor: pointer;
}

.btnIcon:focus {
    outline: none;
}

.btnIcon_edit { background: transparent url("/web3/build/admin/images/pencil.png") no-repeat 0 0; }
.btnIcon_delete { background: transparent url("/web3/build/admin/images/delete.png") no-repeat 0 0; }
.btnIcon_alert { background: transparent url("/web3/build/admin/images/alert.png") no-repeat 0 0; }
.btnIcon_exclamation { background: transparent url("/web3/build/admin/images/exclamation.png") no-repeat 0 0; }
.btnIcon_ticket { background: transparent url("/web3/build/admin/images/workTicket.png") no-repeat 0 0; }
.btnIcon_ok { background: transparent url("/web3/build/admin/images/save.png") no-repeat 0 0; }
.btnIcon_fileDownload { background: transparent url("/web3/build/admin/images/fileIcon.png") no-repeat 0 0; }

.actionRow {
    overflow: hidden;
}

.actionRow-actions {
    float: right;
    padding-top: 23px;
}

.actionRow-bd {
    overflow: hidden;
    margin: 0 -5px;
}

.actionRow-bd_split > * {
    float: left;
    width: 50%;
    padding: 0 5px;
}

.pageHeaderAction {
    float: right;
    margin: 10px 60px 0 0;
    font-size: 13px;
}

.orderSectionHeading {
    clear: both;
    padding: 20px 0 0 10px;
}

.orderSectionHeading h2 {
    font-family: "Proxima N W01 Bold", helvetica, arial;
    line-height: 1.2;
    padding: 0;
    border: 0;
    margin: 0;
    background: transparent;
    color: #838383;
}

.modal-body:before,
.modal-body:after {
    content: " ";
    display: table;
}

.modal-body:after {
    clear: both;
}

.productIdConstrain {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mediaCheckbox {
    overflow: hidden;
}

.mediaCheckbox input[type="checkbox"] {
    float: left;
}

.mediaCheckbox label {
    display: block;
    overflow: hidden;
}

.orderJobSpecs .customerNote p {
    margin-bottom: 5px;
}

.customProductAttributesTable img {
    max-width: 100%;
    height: auto;
}

/* Hide elements when viewing in an iFrame */
body.iFrameView header {
    display: none;
}

body.iFrameView .quick-search {
    display: none;
}

body.iFrameView .back-to-list {
    display: none;
}

body.iFrameView a.customerName {
    pointer-events: none;
    cursor: default;
}

.shipping-rate-adjustments-table {
    width: 100%;
}

.shipping-rate-adjustments-table > colgroup > col:nth-child(1) {
    width: 60%;
}

.shipping-rate-adjustments-table > colgroup > col:nth-child(2) {
    width: 40%;
}

.shipping-rate-adjustment-input {
    width: 50%;
}

.shipping-rate-adjustments-container {
    display: none;
}

a.shipping-rate-adjustments-vendor {
    float: right;
    padding: 16px 0 0 20px;
}

a.shipping-rate-adjustments-vendor.hide {
    background-image: url(/web3/build/admin/images/expand.png);
}

a.shipping-rate-adjustments-vendor.show {
    background-image: url(/web3/build/admin/images/contract.png);
}

.shipping-vendor-rate-adjustments-container {
    margin-top: 10px;
}

.followerField {
    padding-top: 10px;
}

.btnFollow,
.btnFollowing {
    background-color: #000000;
    color: #ffffff;
    border: 1px solid #000000;
    cursor: pointer;
    border-radius: 8px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnFollowing {
    background-color: #ffffff;
    color: #000000;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background: white;
    border: 1px solid black;
    padding: 20px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.popup button {
    margin: 5px;
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #000000;
    border-radius: 5px;
}

.btnUnfollow {
    background-color: #000000;
    color: #ffffff;
}

.btnCancel {
    background-color: #ffffff;
    color: #000000;
}

#assignFollowerAlertForAllJobs {
    float: right;
}
